import * as Actions from '../../../../redux/actions'

import React, { Component } from 'react'
import { member_area_router, validate, validate_fields } from "../../Common/RegExValidate"

import Header from '../../Header/Header'
import Loader from 'react-loader-spinner'
import MemberActionsInitial from "../../Common/MemberActions/MemberActionsInitial"
import PageNotFound from '../../ErrorPage/PageNotFound'
import { Redirect } from "react-router-dom"
import TimelineWizardContainer from "../../Common/TimelineWizardContainer"
import { connect } from 'react-redux'
import { list_container_mode } from '../../Common/RegExValidate'
import HotelPaymentContainer from './PaymentContainer'
import { HeaderMobile } from '../../Header/HeaderMobile'
import "./PaymentPage.css"

class HotelPaymentPage_ extends Component {
    /* update screen size */
    updateDimensions = () => {
        this.props.setScreenSize(window.screen.width, window.screen.height)
        this.props.setBodyClientSize(document.body.clientWidth, document.documentElement.scrollHeight)
        this.props.setAppMenu(document.body.clientWidth)
    }
    /* initial component */
    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions)
        this.updateDimensions()
        this.props.setSwitchContainer(member_area_router.hotelBooking)
        
        this.props.setTripDisplayMode(list_container_mode.timeline)
        this.props.setAppMenuBookingButtonStatus(true)

    }
    /* destory component */
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions)
    }
    render() {
        const lang = this.props.lang
        const { preload, switch_container, tripMode,is_mobile, destinaionListDisplayFormat } = this.props
        return (
            validate(lang, validate_fields.languages) ?
                <div style={{
                    height: "auto",
                }}>
                
                {!is_mobile ?  <Header bg_color={"rgba(25,25,25, 1)"} text_color={"var(--mainWhite)"} /> :
                    <HeaderMobile useTransparent bg_color={"transparent"} text_color={"var(--mainWhite)"}/>}
                         <TimelineWizardContainer listDisplayFormat={member_area_router.booking} />
                         <HotelPaymentContainer props={this.props} />

                    <MemberActionsInitial />
                </div>
                :
                <Redirect to={"error"} component={PageNotFound} />
        )
    }
}


const mapStateToProps = state => {
    return {
        preload: state.Setting.preload,
        switch_container: state.Member.switch_container,
        lang: state.Setting.lang,
        tripMode: state.Member.tripMode,
        destinaionListDisplayFormat: state.Destination.destination.destinaionListDisplayFormat,
        is_mobile: state.Setting.is_mobile,
        
    }
}

const mapDispatchToProps = {
    setBodyClientSize: Actions.setBodyClientSize,

    setScreenSize: Actions.setScreenSize,
    setAppMenu: Actions.setAppMenu,
    setTripDisplayMode: Actions.setTripDisplayMode,
    setScreenVerticalIsTop: Actions.setScreenVerticalIsTop,
    setAppMenuBookingButtonStatus: Actions.setAppMenuBookingButtonStatus,

    setSwitchContainer: Actions.setSwitchContainer,
}

const HotelPaymentPage = connect(mapStateToProps, mapDispatchToProps)(HotelPaymentPage_)
export default HotelPaymentPage
