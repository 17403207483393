import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import './flights.css';
import { AirplaneComponent, FlightBack, FlightDetailComponent, MidAirport, SelectFlight } from './flightsComponents';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DurationLayover, secondsToHms } from '../../../utility/utility';
import InfiniteScroll from 'react-infinite-scroll-component';
import { cancelLoading, saveFlight, sortFlightList, updateDepartureFlight, updateFlightMode, updateFlightPage, updateReturnFlight } from './services/Flights.slice';
import { useHistory } from "react-router-dom"
import { getFlightContractThunk, getFlightsListThunk, getReturnFlightThunk } from './services/Flights.thunks';
import { Loader } from 'semantic-ui-react';
import { CircularProgress } from '@material-ui/core';
import { FlightTestSlice, bookingTestData, departureTestData, returnTestData } from "./services/flightTestData";
import Airline from '../../../assets/images/gairline.png'
import PlayAirline from '../../../assets/images/playLogo.png'

export const FlightListComponent = () => {
    const flightListData = useSelector(state => state.Flights.flightListData);
    const flightMode = useSelector(state => state.Flights.flightData.flightMode);
    const selectedDeparture = useSelector(state => state.Flights.selectedDepartureFlight);
    const searchData = useSelector(state => state.Flights.searchData);
    const loadingMore = useSelector(state => state.Flights.loadingMore);
    const isEmptySearch = useSelector(state => state.Flights.isEmptySearch);

    const loading = useSelector(state => state.Flights.loading);
    const reachedEnd = useSelector(state => state.Flights.reachedEnd);

    const dispatch = useDispatch();

    const checkFilters = (flight) => {
        return flight?.airline && flight?.stops && flight?.duration && flight?.prices && flight?.times;
    }


  



    return (

        <div>
            <div style={{
                marginTop: 10,
                paddingBottom: 20
            }} >
                {(flightListData.length > 0) && <div style={{
                    font: "16px/16px Futura Md BT", color: "#E63155", textAlign: "center", padding: '20px 0px', margin: '20px 0px', border: '1px solid #DFDFDF'
                }}> Free cancellation within 24hrs of booking</div>}


                {/* selected Departure Flight */}

                {selectedDeparture && flightMode === 'round_trip' && searchData && searchData.length > 0 && <div style={{
                    paddingBottom: 10
                }}>
                    <FlightItemComponent
                        flightData={selectedDeparture} isDisplay={true} />


                    <FlightBack city={searchData[0].origin.city} />
                </div>}

                <InfiniteScroll
                    style={{ overflow: 'hidden', width: '100%' }}
                    scrollThreshold={0.9}
                    dataLength={flightListData.length} //This is important field to render the next data
                    hasMore={false}
                // next={loadMore}
                >

                    {flightListData.length > 0 && flightListData.map((flight, index) => {
                        if (checkFilters(flight)) {  ///all filters
                            return (
                                <FlightItemComponent
                                    flightData={flight}
                                    isDisplay={false}
                                    key={index + "flightItem"} />
                            )
                        }
                        return null;
                    })}
                </InfiniteScroll>

                {isEmptySearch && <div style={{
                    font: "21px/21px Futura Md BT", marginTop: 30
                }}>  No results found, please try again.</div>}

                {loadingMore && <div className="flightsLoader" > <CircularProgress thickness={4} style={{ color: '#19BC9B' }} /></div>}
            </div>
        </div>
    )
}

export const FlightItemComponent = ({ flightData, isDisplay }) => {
    const selectedDeparture = useSelector(state => state.Flights.selectedDepartureFlight);
    const selectedFlight = useSelector(state => state.Flights.selectedFlight);
    const flightCurrency = flightData.price_details.display_symbol;
    const flightCurrencyDisplay = flightData.price_details.display_currency;
    const oneclick = useSelector(state => state.PersonalizedTrip.oneclick.active)
    const hotelPPn = useSelector(state => state.PersonalizedTrip.oneclick.hotelPpn)
    const booking_bundles = useSelector(state => state.FormBank.BookingPriceline.booking_bundles);
    const is_mobile = useSelector(state => state.Setting.is_mobile);

    const flightTotalCost = isNaN(flightData.price_details.display_total_fare) && flightData.price_details.display_total_fare ? (flightData.price_details.display_total_fare).toFixed(2) : flightData.price_details.display_total_fare.toFixed(2);
    const flightMode = useSelector(state => state.Flights.flightData.flightMode);
    let flightTicketCost = 0;
    if (isDisplay) {
        flightTicketCost = selectedDeparture.price_details.display_total_fare.toFixed(2);
    }
    else {
        flightTicketCost = selectedDeparture && flightMode === 'round_trip' ? Number(flightData.price_details.display_total_fare - selectedDeparture.price_details.display_total_fare).toFixed(2) : flightData.price_details.display_total_fare_per_ticket.toFixed(2)
    }

    const flightSlice = flightData.slice_data;
    const cost = flightCurrency + flightTicketCost;
    const isRoundTrip = selectedDeparture && flightMode === 'round_trip' && !isDisplay;

    const dispatch = useDispatch()
    const history = useHistory()

    const flightPpn = selectedFlight?.ppn_contract_bundle

    const getHotelPpns = ()=>{
        if(booking_bundles && booking_bundles?.length > 0){
            const ppns =  booking_bundles?.map((bundle)=> bundle?.ppn_book_bundle);
            return ppns?.length > 0 ? ppns?.toString():null
        }
        return null
    }
    const selectFlight = (flight) => {

        if (flightMode === 'round_trip') {
            window.scrollTo(0, 0);
            if (selectedDeparture) {
                dispatch(updateReturnFlight(flight));
                dispatch(getFlightContractThunk())

                if(oneclick){
                    const ppns = getHotelPpns()
                    
                    history.push(`/package?hotelBundle=${ppns}&bundleId=${flight?.ppn_contract_bundle}`) 
                }
                else{
                    history.push('/flight/recap')
                }
                
            }
            else {
                dispatch(getReturnFlightThunk(flight))
                dispatch(updateDepartureFlight(flight));
            }
        }
        else {
            dispatch(updateDepartureFlight(flight));
            dispatch(getFlightContractThunk())
            if(oneclick ){
                const ppns = getHotelPpns()
                history.push(`/package?hotelBundle=${ppns}&bundleId=${flight?.ppn_contract_bundle}`) 
            }
            else{
                history.push('/flight/recap')
            }
        }
        dispatch(updateFlightMode(flightMode))  //set flight mode 
        if (flight && flight.slice_data) {
            const savedFlightData = flight.slice_data.map((data) => {
                return {
                    airline_logo: data.airline.logo || "",
                    departure_airport_code: data.departure.airport.code || "",
                    arrival_airport_code: data.arrival.airport.code || "",
                    departure_date: data.departure.datetime.date || "",
                    arrival_date: data.arrival.datetime.date || "",
                    departure_time: data.departure.datetime.time_24h || "",
                    arrival_time: data.arrival.datetime.time_24h || "",
                    flight_duration: data.info.duration || ""
                }
            })
            dispatch(saveFlight(savedFlightData))
        }
    }

    const days = (date_1, date_2) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(date_1.getFullYear(), date_1.getMonth(), date_1.getDate());
        const utc2 = Date.UTC(date_2.getFullYear(), date_2.getMonth(), date_2.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }



    return (
        <Grid container style={{ marginBottom: 15 }} >

            <Grid item xs={12} sm={12} md={9}  lg={10} >
                {flightSlice && flightSlice.map((slice, sliceIndex) => {
                    const isOG = slice?.airline?.code === "OG" && !slice?.airline?.logo;
                    const logo = isOG ?PlayAirline: slice.airline.logo;
                    const flightSliceData = slice.flight_data;
                    let operated;
                    const notes = slice?.info?.notes;
                    let airlines = "";
                    Object.keys(flightSliceData).forEach(key => {
                        const val = flightSliceData[key]
                        const opr = val?.info?.marketing_airline;
                        const notInclude = !airlines.includes(opr);
                        if (opr && opr.length > 0 && notInclude) {
                            airlines = airlines.concat(opr, '/');
                        }
                    });
                    airlines = airlines.slice(0, -1)

                    operated = slice?.airline?.name ?
                        ` - Operated by ${airlines}  - Total flight duration: ${secondsToHms(slice.info.duration)} ` : ``;



                    return (
                        <Grid container key={sliceIndex} style={{ border: '1px solid #DFDFDF', height: 'auto', padding: '20px 0px 20px' }}>
                            <img src={logo}
                             onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=Airline;
                              }}
                               height={25} alt="logo" style={{ paddingLeft: 40, marginBottom: 10 }} />

                            <Grid container spacing={0} className="flightTimeline">
                                <div className='line'></div>



                                {flightSliceData && flightSliceData.map((flight, index) => {

                                    let DataComponent = [];



                                    const date1 = new Date(flightSliceData[0]?.departure.datetime.date_time);
                                    const date2 = new Date(flightSliceData[flightSliceData.length - 1]?.arrival.datetime.date_time)
                                    const diffDays = days(date1, date2);

                                    if (is_mobile) {
                               
                                        if (index === 0) {
                                            const date = moment(flight.departure.datetime.date).format('MMMM Do');
                                            DataComponent.push(
                                                <Grid item xs={2} key={index + 'depart'} style={{ maxWidth:"38%"}}  >
                                                    <div style={{ textAlign: 'right', background: 'white'}}>
                                                        <FlightDetailComponent flightText={'Depart From'} airportCode={flight.departure.airport.code}
                                                            country={flight.departure.airport.city} date={date} time={flight.departure.datetime.time_12h} />
                                                    </div>
                                                </Grid>
                                            )

                                            DataComponent.push(
                                                <Grid item xs key={index + "airplane"} >
                                                    <div style={{ marginTop: 49 }}>
                                                        <AirplaneComponent time={flightSliceData.length === 1 ? secondsToHms(flight.info.duration) : "..."} />
                                                    </div>
                                                </Grid>
                                            )
                                        }



                                        if (index === flightSliceData.length - 1) {

                                            const date = moment(flight.arrival.datetime.date).format('MMMM Do');

                                            DataComponent.push(
                                                <Grid item xs={2} key={index + 'arrive'} style={{ maxWidth:"38%"}}>
                                                    <div style={{ textAlign: 'left', background: 'white' }}>
                                                        <FlightDetailComponent flightText={'Arrive at'} airportCode={flight.arrival.airport.code} diffDays={diffDays}
                                                            country={flight.arrival.airport.city} date={date} time={flight.arrival.datetime.time_12h} />
                                                    </div>
                                                </Grid>
                                            )
                                        }

                                    }
                                    else {
                                        if (index === 0) {
                                            const date = moment(flight.departure.datetime.date).format('MMMM Do');
                                            DataComponent.push(
                                                <Grid item md={2} key={index + 'depart'}  >
                                                    <div style={{ textAlign: 'right', background: 'white' }}>
                                                        <FlightDetailComponent flightText={'Depart From'} airportCode={flight.departure.airport.code}
                                                            country={flight.departure.airport.city} date={date} time={flight.departure.datetime.time_12h} />
                                                    </div>
                                                </Grid>
                                            )
                                        }

                                        DataComponent.push(
                                            <Grid item md key={index + "airplane"} >
                                                <div style={{ marginTop: 49 }}>
                                                    <AirplaneComponent time={secondsToHms(flight.info.duration)} />
                                                </div>
                                            </Grid>
                                        )

                                        if (index === flightSliceData.length - 1) {

                                            const date = moment(flight.arrival.datetime.date).format('MMMM Do');

                                            DataComponent.push(
                                                <Grid item md={2} key={index + 'arrive'} style={{ marginLeft: 6 }}>
                                                    <div style={{ textAlign: 'left', background: 'white' }}>
                                                        <FlightDetailComponent flightText={'Arrive at'} airportCode={flight.arrival.airport.code} diffDays={diffDays}
                                                            country={flight.arrival.airport.city} date={date} time={flight.arrival.datetime.time_12h} />
                                                    </div>
                                                </Grid>
                                            )
                                        }

                                        else {
                                            const next = index + 1 <= flightSliceData.length - 1 ? flightSliceData[index + 1] : null;
                                            const time = next ? DurationLayover(flight.arrival.datetime.date_time, next.departure.datetime.date_time) : '';

                                            DataComponent.push(
                                                <Grid item md key={index + 'connect'}  >
                                                    <div style={{ margin: '0 auto', marginTop: 49, maxWidth: 130 }}>
                                                        <MidAirport time={time} airportCode={flight.arrival.airport.code} city={flight.arrival.airport.city} />
                                                    </div>
                                                </Grid>
                                            )

                                        }

                                    }


                                    return DataComponent;
                                })
                                }



                            </Grid>
                            <p style={{ marginTop: 20, marginLeft: 50, marginBottom: 0, width: "100%", font: "14px/14px Futura Md BT" }}> {slice?.airline?.name}<span style={{ color: '#6b6b6b', font: "14px/14px Futura Md BT" }}>{operated} </span></p>

                            {flightSliceData &&
                                <div style={{ paddingTop: 15,width: '80%' }}>
                                    {Object.keys(flightSliceData).map((key, index) => {
                                        const val = flightSliceData[key]
                                        const notes = val?.info?.notes;
                                        return (<div style={{ width: '100%', }}>
                                            {notes && Object.keys(notes).map(key => {
                                                const note = notes[key]

                                                return (
                                                    <p style={{ marginTop: 3, marginBottom: 3, width: "100%", marginLeft: 50, lineHeight: 1.3, font: "14px/14px Futura  Lt BT", color: '#E63155', }}> {note?.value}</p>
                                                )
                                            })}
                                        </div>)
                                    })}
                                </div>}
                        </Grid>
                    )
                })
                }


            </Grid>

            {/* spacing hack */}
            <Grid item xs={false} sm={false} md={1} style={{ maxWidth: 10 }}>
            </Grid>


            <Grid item md xs={12} style={{ border: '1px solid #DFDFDF' }}>
                <SelectFlight isDisplay={isDisplay} selectFlight={() => { selectFlight(flightData) }} isRoundTrip={isRoundTrip} flightSymbol={flightCurrency} cost={cost} total={flightTotalCost} currency={flightCurrencyDisplay} />
            </Grid>

        </Grid>
    )
}


