import * as Actions from '../../../../redux/actions'

import { BedIcon, CheckBoxIcon, CheckedBoxIcon } from "../SVGBank"
import { DirectionsRenderer, GoogleMap, withGoogleMap } from "react-google-maps"
import { compose, lifecycle, withProps } from "recompose"

import { DELAY_ZERO_SECOND, DEFAULT_MAP_ZOOM, GOOGLE_API_KEY } from "../RegExValidate"
import ItineraryMarkers from "../ItineraryMarkers"
import PlacesMarkers from "../PlacesMarkers"
import PropTypes from "prop-types"
import React from 'react'
import { connect, useSelector } from 'react-redux'
import mapStyles from "../../TripPage/mapStyles"
import moment from "moment"
import { list_container_mode, member_area_router } from "../RegExValidate"
import HotelPlacesMarkers from '../HotelPlacesMarkers'
import FeatureTypeIcon from '../FeatureTypeIcon'
import { debounce } from 'lodash';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer'
import { generateMapAPIKey } from '../../../../utility/utility'

// import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer"

/* wrap map high order component */
const WrappedMap = compose(
    withProps({
        googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${generateMapAPIKey()}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withGoogleMap,
    lifecycle({
        componentDidUpdate(prevProps) {
            const { tripId, activities, activeDay, destination, hotel_form_state } = this.props

            if (prevProps.tripId !== tripId
                || prevProps.activeDay.tripDestinationId !== activeDay.tripDestinationId
                || prevProps.activeDay.dayInTrip !== activeDay.dayInTrip
                || prevProps.activeDay.dayInTripDestination !== activeDay.dayInTripDestination
                || prevProps.activities.length !== activities.length) {
                let waypts = []
                let first_lat = ""
                let first_lng = ""
                activities.map((activity) => {
                    first_lat = activity.activityLatitude
                    first_lng = activity.activityLongitude
                    waypts.push(new window.google.maps.LatLng({
                        lat: parseFloat(activity.activityLatitude),
                        lng: parseFloat(activity.activityLongitude)
                    }))
                    return ""
                })
                let first = waypts.shift()
                let last = waypts.pop()
                let way_points = waypts.map(obj => {
                    let key_obj = {}
                    key_obj["location"] = obj
                    key_obj["stopover"] = true
                    return key_obj
                })
                if (first !== undefined && last !== undefined) {
                    // console.log("display directions")
                    const DirectionsService = new window.google.maps.DirectionsService()

                    DirectionsService.route({
                        origin: first,
                        destination: last,
                        waypoints: way_points,
                        provideRouteAlternatives: true,
                        optimizeWaypoints: true,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                    }, (result, status) => {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            this.setState({
                                directions: { ...result },
                                display: true
                            })
                        } else {
                            console.log(`Driving error fetching directions ${result}`)
                            DirectionsService.route({
                                origin: first,
                                destination: last,
                                waypoints: way_points,
                                provideRouteAlternatives: true,
                                optimizeWaypoints: true,
                                travelMode: window.google.maps.TravelMode.WALKING,
                            }, (result, status) => {
                                if (status === window.google.maps.DirectionsStatus.OK) {
                                    this.setState({
                                        directions: { ...result },
                                        display: true
                                    })
                                } else {
                                    console.error(`Walking error fetching directions ${result}`)
                                }
                            })
                        }
                    })
                } else if (first !== undefined) {
                    // console.log(" no directions")
                    this.setState({
                        directions: null,
                        display: false,
                    })
                    // this.props.setMapCenter({
                    //     lat: parseFloat(first_lat),
                    //     lng: parseFloat(first_lng)
                    // })
                    this.props.setZoom(DEFAULT_MAP_ZOOM)
                } else if (first === undefined) {

                    // console.log(" no activity and directions")
                    this.setState({
                        directions: null,
                        display: false
                    })
                    // this.props.setMapCenter({
                    //     lat: parseFloat(hotel_form_state.city_latitude),
                    //     lng: parseFloat(hotel_form_state.city_longitude)
                    // })
                    this.props.setZoom(DEFAULT_MAP_ZOOM)
                }
            }
        },
        componentDidMount() {
            const { activities, destination, handleCenterChanged, hotel_form_state } = this.props
            handleCenterChanged()

            let waypts = []
            let first_lat = ""
            let first_lng = ""
            activities.map((activity) => {
                first_lat = activity.activityLatitude
                first_lng = activity.activityLongitude
                waypts.push(new window.google.maps.LatLng({
                    lat: parseFloat(activity.activityLatitude),
                    lng: parseFloat(activity.activityLongitude)
                }))
                return ""
            })
            let first = waypts.shift()
            let last = waypts.pop()
            let way_points = waypts.map(obj => {
                let key_obj = {}
                key_obj["location"] = obj
                key_obj["stopover"] = true
                return key_obj
            })
            if (first !== undefined && last !== undefined) {
                // console.log("display directions")
                const DirectionsService = new window.google.maps.DirectionsService()

                DirectionsService.route({
                    origin: first,
                    destination: last,
                    waypoints: way_points,
                    provideRouteAlternatives: true,
                    optimizeWaypoints: true,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({
                            directions: { ...result },
                            display: true
                        })
                    } else {
                        console.log(`Driving error fetching directions ${result}`)
                        DirectionsService.route({
                            origin: first,
                            destination: last,
                            waypoints: way_points,
                            provideRouteAlternatives: true,
                            optimizeWaypoints: true,
                            travelMode: window.google.maps.TravelMode.WALKING,
                        }, (result, status) => {
                            if (status === window.google.maps.DirectionsStatus.OK) {
                                this.setState({
                                    directions: { ...result },
                                    display: true
                                })
                            } else {
                                console.error(`Walking error fetching directions ${result}`)
                            }
                        })
                    }
                })
            } else if (first !== undefined) {
                // console.log(" no directions")
                this.setState({
                    directions: null,
                    display: false,
                })
                // this.props.setMapCenter({
                //     lat: parseFloat(first_lat),
                //     lng: parseFloat(first_lng)
                // })
                this.props.setZoom(DEFAULT_MAP_ZOOM)
            } else if (first === undefined) {

                // console.log(" no activity and directions")
                this.setState({
                    directions: null,
                    display: false
                })
                // this.props.setMapCenter({
                //     lat: parseFloat(hotel_form_state !== undefined && hotel_form_state.city_latitude !== "" ? hotel_form_state.city_latitude
                //         : destination !== undefined ? destination.latitude : 0),
                //     lng: parseFloat(hotel_form_state !== undefined && hotel_form_state.city_longitude !== "" ? hotel_form_state.city_longitude
                //         : destination !== undefined ? destination.longitude : 0),
                // })
                this.props.setZoom(destination !== undefined ? DEFAULT_MAP_ZOOM : 1)
            }
        }
    })
)((props) => {
    const [trackInfoArray, setTrackInfoArray] = React.useState([]);



    const pushPlacesWindow = (id) => {
        const tempArray = [];
        tempArray.push(id)
        setTrackInfoArray(tempArray);
    }

    const clearPlacesWindow = () => {
        setTrackInfoArray([]);
    }

    const checkWindowOpen = (id) => {
        const showWindow = trackInfoArray.find(element => element === id);
        return showWindow ? true : false;
    }

    const mapClick = () => {
        clearPlacesWindow()
    }





    return (<GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={props.mapCenter}
        center={props.mapCenter}
        options={{
            controlSize: 20,
            mapTypeControl: false,
            fullscreenControl: false,
            minZoom: 4,
            gestureHandling:props?.showRefresh ?"none":"auto",
            // maxZoom: 20,
            styles: mapStyles
        }}
        onCenterChanged={props.handleCenterChanged}
        onZoomChanged={props.handleZoomChanged}
        ref={props.refMap}
        onClick={mapClick}
    >
        {/* <MarkerClusterer gridSize={30}> */}
        {props.activities.length > 0 && props.activities.map((establishment, index_activity) => {
            const zoom = props && props.refMap ? props.refMap?.current?.getZoom() : 55;
            const lengthActivities = props.activities.length;
            const typeID = "4";
            const type = props.types ? props.types.find(type => type.typeID === typeID) : null;
            const icon = type && type.icon ? type.icon : null;
            return (
                <HotelPlacesMarkers zoom={zoom} icon={"BedIcon"} establishment={establishment} index_establishment={index_activity}
                    pushPlacesWindow={pushPlacesWindow}
                    clearPlacesWindow={clearPlacesWindow}
                    checkWindowOpen={checkWindowOpen}
                />
            )
        })}

            <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={100}
            maxZoom={15}
            defaultMinimumClusterSize={10}
            styles={[{
                height: 36,
                url: "https://storage.googleapis.com/muvimages/muv_assets/icons/Activity%20cluster2.png",
                width: 35,
                textColor: "white",
            },]}
        >
            {props.selectedTypes && props.selectedTypes.length > 0 &&
                props.placesNearByCenter.length > 0 && props.placesNearByCenter.map((establishment, index_establishment) => {


                    const typeID = establishment?.subType?.typeId;
                    const type = props.types ? props.types.find(type => type.typeID === typeID) : null;
                    const icon = type && type.icon ? type.icon : null;
                    const zoom = props && props.refMap ? props.refMap?.current?.getZoom() : 55;
                    return (
                        <React.Fragment key={index_establishment}>

                            {!props.activities.find(a => a.activityEstabID === establishment.id) &&

                                <PlacesMarkers zoom={zoom} icon={icon} establishment={establishment} index_establishment={index_establishment} placesNearByCenter={props.placesNearByCenter}
                                    pushPlacesWindow={pushPlacesWindow}
                                    checkWindowOpen={checkWindowOpen}
                                    clearPlacesWindow={clearPlacesWindow} />


                            }

                        </React.Fragment>
                    )
                })}
        </MarkerClusterer>


    </GoogleMap>)

}
)

/* trip map component */
const TripMapPricelne_ = ({
    app_menu_height,
    screenSizeHeight,
    lang,
    getPriceLineHotels,
    uuid_auth,
    uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID",
    is_mobile,
    activeDay,
    feature,
    activeTrip,
    activities = activeTrip.activities,
    destinations = activeTrip.tripDestinations,
    noDate = String(activeTrip.tripNoDate),
    tripStartDate = activeTrip.tripStartDate,
    setSwitchFeatureTypeContainerOfFeature,
    getHotelsNearbyMapCenter,
    hotelSearchForm,
    setActiveTripDestinationId,
    lazyLoading,
    establishments,
    placesNearByCenter,
    getEstablishmentsNearbyMapCenter,
    switch_container,
    tripMode,
    selectedTypes,
    generalInfo
}) => {

    const from_destination = switch_container === member_area_router.hotelSearch && tripMode === list_container_mode.map
    const destinationsFilter = destinations.filter(d => d.td_id === activeDay.tripDestinationId)
    let destination = destinations.length > 0 && destinations[0] !== undefined ? destinations[0] : undefined
    let hotel_form_state = hotelSearchForm !== undefined && hotelSearchForm.hotel_form_state !== undefined
        ? hotelSearchForm.hotel_form_state : null
        const [refreshText, setRefreshText] = React.useState("Search this area");

        const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
        const fullCurrency =  selectedCurrency ? selectedCurrency.toUpperCase(): "CAD";
        
    const containerH = screenSizeHeight - Math.ceil(app_menu_height / 2)

    const [showRefresh, setShowRefresh] = React.useState(false);

    //updating  activities of the iternary
    const activitiesInTripDestinationDay = activities.filter(a => a.tripDestinationId === activeDay.tripDestinationId && a.activityDayInDestination === activeDay.dayInTripDestination)

    const [mapCenter, setMapCenter] = React.useState({
        lat: parseFloat(hotelSearchForm !== undefined && hotelSearchForm.hotel_form_state !== undefined && hotelSearchForm.hotel_form_state.city_latitude !== "" ? hotelSearchForm.hotel_form_state.city_latitude
            : destination !== undefined ? destination.latitude : 0),
        lng: parseFloat(hotelSearchForm !== undefined && hotelSearchForm.hotel_form_state !== undefined && hotelSearchForm.hotel_form_state.city_longitude !== "" ? hotelSearchForm.hotel_form_state.city_longitude
            : destination !== undefined ? destination.longitude : 0),
    })
    const [zoom, setZoom] = React.useState(destination !== undefined ? DEFAULT_MAP_ZOOM : (hotelSearchForm.hotel_form_state.city_latitude !== "" ? DEFAULT_MAP_ZOOM : 1))
    const types = feature && feature.types ? [...feature.types] : null;
    const handleSetActiveTripDestinationId = (destination, dayInTrip, dayInTripDestination) => {
        setActiveTripDestinationId(destination, dayInTrip, dayInTripDestination)
        document.getElementById(destination.td_id + "_" + dayInTripDestination + "_" + dayInTrip) !== null
            && document.getElementById(destination.td_id + "_" + dayInTripDestination + "_" + dayInTrip)
                .scrollIntoView({ behavior: 'smooth', block: 'start' })
    }


    // updating the places markers
    const refMap = React.useRef(null)

    const fetchHotelByCoordinate =React.useCallback(()=>{
        const lat = refMap?.current?.getCenter()?.lat()
        const lng = refMap?.current?.getCenter()?.lng()
        const formState= hotel_form_state? {
            ...hotel_form_state,
            city_id:null,
            latitude:lat,
            longitude:lng
        }:null

        if(formState){
            getPriceLineHotels(formState, lazyLoading,fullCurrency,null)     // FormBankAction  [fetchPricelineHotels]
        }

   
    },[refMap])

    React.useEffect(()=>{
        if(showRefresh){
            fetchHotelByCoordinate()
        }
    },[showRefresh])

    const handleCenterChanged = () => {

        setTimeout(() => {
            setShowRefresh(true)
           refreshMap()
      
        }, 2000);
    }
    const handleZoomChanged = () => {
        const lat = refMap?.current?.getCenter()?.lat()
        const lng = refMap?.current?.getCenter()?.lng()
        const zoom = refMap?.current?.getZoom()
        delay(function () {
            //getHotelsNearbyMapCenter(lat, lng, zoom, lang)
        }, DELAY_ZERO_SECOND);
    }

    const delay = (() => {
        let timer = 0
        return (callback, ms) => {
            clearTimeout(timer)
            timer = setTimeout(callback, ms)
        }
    })()
    const checkType = (type) => {
        return selectedTypes.find(val => val.typeID === type.typeID)
    }
  

    const refreshMap = ()=>{
        setRefreshText("Searching...")
        fetchEstablishments()
        setTimeout(() => {
            setRefreshText("Search this area")
            setShowRefresh(false)
        }, 5000);
    }

    const fetch = () => {
        const reference = refMap;
        if (reference && reference.current) {
                if (selectedTypes && selectedTypes.length > 0) {
                    let ne = reference?.current?.getBounds()?.getNorthEast();
                    let sw = reference?.current?.getBounds()?.getSouthWest();
                    if (ne && sw) {
                        const coordinates = [{ lat: ne.lat(), lng: sw.lng() }, { lat: sw.lat(), lng: ne.lng() }];
                        getEstablishmentsNearbyMapCenter(coordinates, lang, selectedTypes)
                    }

            }


        }
    }

    const fetchEstablishments = debounce (fetch, 1000)



    const changeType = (type) => {
        setSwitchFeatureTypeContainerOfFeature("1", lang, feature.types, type)
    }

    const selectTag =(type)=>{
        changeType(type)
        refreshMap()
    }


    React.useEffect(()=>{
        changeType(null)
      
    },[]) //deselect type see


    //console.log("TripMap activeDay: ")
    //console.log(activeDay)
    //console.log("TripMap activeTrip: ")
    //console.log(activeTrip)



    return (
        <div style={{
            height: containerH,
            width: "100%",
            position: "absolute",
            top: "0",
        }} className="content_container margin_bottom_20 inline-block" id="google_map_wrap">
            <WrappedMap
                tripId={activeTrip.tripId}
                activities={establishments}
                activeDay={activeDay}
                destination={destination}
                placesNearByCenter={placesNearByCenter}
                selectedTypes={selectedTypes}
                hotel_form_state={hotel_form_state}
                zoom={zoom}
                showRefresh={showRefresh}
                setZoom={setZoom}
                mapCenter={mapCenter}
                setMapCenter={setMapCenter}
                handleCenterChanged={handleCenterChanged}
                handleZoomChanged={handleZoomChanged}
                refMap={refMap}
                types={types}
            />


            <div
                style={is_mobile ?
                    {
                        position: "absolute",
                        top: "7%",
                        right: "0%",
                        left: "1%",
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                        scrollbarWidth: "none", scrollbarColor: 'transparent',
                        width: "100%",
                        height: 50,
                        zIndex: "1",
                        margin: "0",
                    }
                    : {
                        position: "absolute",
                        top: "2%",
                        right: "22%",
                        zIndex: "1",
                        margin: "0",
                    }}>
               

                {types !== undefined && types.length > 0
                    && types.map((type, type_index) => {
                        return (
                            <div
                                key={type.toString() + type_index}
                                onClick={() => selectTag(type)}
                                style={{
                                    backgroundColor: checkType(type) ? "#0d3a7d" : "var(--mainWhite)",
                                    color: checkType(type) ? "var(--mainWhite)" : "var(--frameBlack)",
                                    border: checkType(type) ? "1px solid #0d3a7d" : "1px solid #E8E8E8",
                                    height: "30px",
                                    display: "inline-flex",
                                    borderRadius: "25px",
                                    flexWrap: "wrap",
                                    cursor: "pointer",
                                    verticalAlign: "middle",
                                    padding: ".375rem .75rem",
                                    marginRight: "6px",
                                    visibility: refreshText === "Searching..." ? "hidden" : "visible"
                                }}>
                                <FeatureTypeIcon
                                    icon={type.icon}
                                    fill={checkType(type) ? "var(--mainWhite)" : "#707070"}
                                    style={{
                                        width: "15px",
                                        height: "16px",
                                        marginLeft: "5px",
                                        marginRight: "10px",
                                    }} />
                                <div style={{
                                    font: "16px/18px Futura Lt BT",
                                    color: checkType(type) ? "var(--mainWhite)" : "var(--frameBlack)",
                                }}>{type.typeName}</div>
                            </div>
                        )
                    })
                }




            </div>

            {showRefresh && <div


                style={{
                    position: "absolute",
                    top: is_mobile ? "15%" : "7%",
                    right: "14%",
                    zIndex: "1",
                    margin: "0",
                    left: is_mobile ? "10%" : "50%",
                    marginLeft: -20
                }}>

                <button onClick={() => refreshMap()}
                    disabled={refreshText === "Searching..."} style={{
                        backgroundColor: "#0d3a7d",
                        color: "var(--mainWhite)",
                        height: "30px",
                        display: "inline-flex",
                        borderRadius: "25px",
                        flexWrap: "wrap",
                        cursor: "pointer",
                        verticalAlign: "middle",
                        padding: ".375rem .75rem",
                        marginRight: "6px",
                        outline: 0,
                        font: "16px/18px Futura Lt BT",
                    }}>{refreshText}</button>
            </div>}


        </div>
    )
}

TripMapPricelne_.propTypes = {
    activeTrip: PropTypes.object.isRequired
}
const mapStateToProps = state => {
    return {
        lang: state.Setting.lang,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        bodyClientWidth: state.Setting.htmlBody.bodyClientWidth,
        screenSizeHeight: window.innerHeight,
        establishments: state.FormBank.HotelSearch.establishments,
        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
        activeDay: state.FormBank.TimelineWizard.activeDay,
        uuid_auth: state.Member.authModal.uuid,
        feature: state.Feature.feature,
        placesNearByCenter: state.FormBank.Map.placesNearByCenter,
        generalInfo: state.Destination.destination.generalInfo,
        switch_container: state.Member.switch_container,
        tripMode: state.Member.tripMode,
        selectedTypes: state.Feature.feature.loading.selectedTypes,
        is_mobile: state.Setting.is_mobile,
        hotelSearchForm: state.FormBank.HotelSearch,
        lazyLoading: state.FormBank.HotelSearch.lazyLoading,
    }
}

const mapDispatchToProps = {
    getHotelsNearbyMapCenter: Actions.getHotelsNearbyMapCenter,
    setActiveTripDestinationId: Actions.setActiveTripDestinationId,
    getEstablishmentsNearbyMapCenter: Actions.getEstablishmentsNearbyMapCenter,
    setSwitchFeatureTypeContainerOfFeature: Actions.setSwitchFeatureTypeContainerOfFeature,
    getPriceLineHotels: Actions.getPriceLineHotels,
}
const TripMapPricelne = connect(mapStateToProps, mapDispatchToProps)(TripMapPricelne_)
export default TripMapPricelne
