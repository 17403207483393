import * as Actions from '../../../../redux/actions'

import { EllipsisHIcon, EllipsisVIcon, TimesIcon } from "../SVGBank"

import ActivityDuration from './ActivityDuration'
import { ActivityStartTime } from './ActivityStartTime'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import DeleteActivity from './DeleteActivity'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import { connect } from 'react-redux'
import { list_container_mode } from '../RegExValidate'
import { makeStyles } from '@material-ui/core/styles'
import { member_area_router } from '../RegExValidate'
import { ArrowDownward, ArrowUpward, MoreHoriz } from '@material-ui/icons'
import { addTimes, compareTimes } from '../../DestinationPage/Establishments'

// import { ActivityChangeDate } from './ActivityChangeDate'


/*  inject css to menu component */
const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-block',
        position: "relative",
    },
    positionTimeline: {
        zIndex: "35",
        top: "-70px !important",
        left: "0px !important",
        /*backgroundColor: "var(--mainGreen)",*/
    },
    position: {
        zIndex: "35",
        top: "-25px !important",
        left: "-135px !important",
    },
    positionCalendar: {
        zIndex: "35",
        top: "-20px !important",
        left: "-125px !important",
    },
    backgroundSelection: {
        '&:hover': {
            backgroundColor: "var(--mainGreen)"
        },
        minHeight: "10px"
    },
    paper: {
        marginRight: theme.spacing(2),
    },

    hr: {
        width: "65%",
        height: "1px",
        display: "block",
        position: "relative",
        marginRight: "10%",
        marginTop: "5px",
        marginLeft: "25%",
        marginBottom: "5px",
        border: "1px dashed rgba(0,0,0,.1)",
    },
    timesDiv: {
        marginRight: "10px",
        marginLeft: "90px",
        display: "inline-block",
        textAlign: "right",
        cursor: "pointer",
    },
    hidden: {
        zIndex: -1,
        top: "-5000px !important",
    },
    ellipsis: {
        // backgroundColor: "var(--mainGreen)", 
        width: "140px",
        height: "auto",
        margin: "7px",
    }
}))


/* menu list composition function component 

param  trip object

*/
const TripEllipsisTimeline_ = ({deleteActivityToDayOfTripDestinationInTrip,destination,activeTripDay,activeTrip, lang,uuid,activeTripDestinationId,activeDayInTripDestination,addActivityToTripDestinationInTrip, activeDay, lengthDestinationDays, dayInTripDestination, lengthActivitiesInDestinationDay, indexActivity, switch_container, tripMode, format = "ellipsis_h", activity, is_mobile }) => {


    const classes = useStyles()
    const [open, setOpen] = React.useState(false)
    const [disableEllipsis, setDisableEllipsis] = React.useState(false)

    const handleEllipsis = (event) => {
        event.preventDefault()
        setOpen(prevOpen => !prevOpen)
    }
    const anchorRef = React.useRef(null)

    const handleClose = event => {
        if (!disableEllipsis) {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return
            }
            setOpen(false)
        }
    }

    const isFirst = dayInTripDestination == 1;
    const isLast = dayInTripDestination == lengthDestinationDays;
    const showUpDown = (!isFirst && !isLast)
    const activeTripId = activeTrip.tripId
    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
    let activeDestination = activeTrip.tripDestinations && activeTrip.tripDestinations.find(destination => destination.td_id === activeTripDestinationId);
    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
    const daysInDestination = activeDestination && activeDestination.spanningDays;
    const activitiesInActiveDestinationDay = activeTrip.activities && activeTrip.activities.filter(a => a.activityDayInDestination === activeTripDay.dayInTripDestination && a.tripDestinationId === activeTripDay.tripDestinationId)
    const lastActivityInDay = activitiesInActiveDestinationDay && activitiesInActiveDestinationDay.length > 0 &&  activitiesInActiveDestinationDay[activitiesInActiveDestinationDay.length-1]
    const lastActivityTime = lastActivityInDay && addTimes(lastActivityInDay.activityStartTime,lastActivityInDay.activityDuration);
    const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""
    const nextDayInTrip = activeTripDay && activeTripDay.dayInTrip && daysInDestination && daysInDestination.includes(Number(activeTripDay.dayInTrip)+1) ? Number(activeTripDay.dayInTrip)+1 : activeTripDay.dayInTrip   ;
    const nextDayInTripDestination = activeTripDay && activeTripDay.dayInTripDestination && daysInDestination && daysInDestination.includes(Number(activeTripDay.dayInTrip)+1) ? Number(activeTripDay.dayInTripDestination)+1: activeTripDay.dayInTripDestination ;
    const tripDuration = activeTrip.tripDuration
    const after6pmCutoff = lastActivityTime && compareTimes(lastActivityTime,"18:00");
    const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))

    
    const moveUp = () => {
        const newDay = activity?.activityDayInDestination? Number(activity?.activityDayInDestination) - 1 : 0;
        const estTypeID = activity?.activityTypes?.[0]?.typeID

        deleteActivityToDayOfTripDestinationInTrip(lang, uuid, activeTripDestinationId, activity?.activityID, activeTripId) //remove first

        addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, newDay, activity?.activityEstabID, activeTripId, last_establishmentId, tripDuration, destination?.id, activeTripDestinationIds,
            true, 1, 3, activity?.activityID,
            after6pmCutoff, nextDayInTripDestination, nextDayInTrip, estTypeID)
      setOpen(false)
          
    }


    const moveDown = () => {
        const newDay = activity?.activityDayInDestination? Number(activity?.activityDayInDestination) + 1 : 0;
        const estTypeID = activity?.activityTypes?.[0]?.typeID;

        deleteActivityToDayOfTripDestinationInTrip(lang, uuid, activeTripDestinationId, activity?.activityID, activeTripId) //remove first

        addActivityToTripDestinationInTrip(lang, uuid, activeTripDestinationId, newDay, activity?.activityEstabID, activeTripId, last_establishmentId, tripDuration, destination?.id, activeTripDestinationIds,
            true, 1, 3, activity?.activityID,
            after6pmCutoff, nextDayInTripDestination, nextDayInTrip, estTypeID)
            setOpen(false)
          
    }



    return (
        <div className={classes.root}>
            <div
                ref={anchorRef} className="addActivity"
                style={{ cursor: "pointer" }}
                onClick={e => handleEllipsis(e)}>

                {format === "ellipsis_h" && <div style={{

                    color: tripMode === list_container_mode.calendar ? "#FFFFFF" : "#202020"
                }}> <MoreHoriz fontSize="large" color={tripMode === list_container_mode.calendar ? "#FFFFFF" : "#202020"} /></div>}
                {format === "ellipsis_v" && <EllipsisVIcon height={'25'} fill={tripMode === list_container_mode.calendar ? "#FFFFFF" : "#C2C2C2"} />}
            </div>
            <ClickAwayListener onClickAway={handleClose}>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    className={format === "ellipsis_h" ? (tripMode === list_container_mode.calendar ? classes.positionCalendar : classes.positionTimeline) : (tripMode === list_container_mode.calendar ? classes.positionCalendar : classes.position)}
                    transition
                    disablePortal
                    placement={is_mobile ? "left-start" : "bottom-start"}
                    modifiers={{
                        flip: {
                            enabled: false,
                        },
                    }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            className={classes.ellipsis}
                            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                        >
                            <Paper>
                                <MenuList
                                    autoFocusItem={false}
                                    disableListWrap={true}
                                    dense={false}
                                    id="trip_ellipsis_timeline" >
                                    <div className={classes.timesDiv} onClick={handleClose}>{!is_mobile ? <TimesIcon width={15} height={15} /> : <TimesIcon width={10} height={10} />}</div>
                                    {/* <MenuItem  ><ActivityChangeDate activity={activity} from={"ellipsis"} disableEllipsis={disableEllipsis} setDisableEllipsis={setDisableEllipsis} closeEllipsis={setOpen} /></MenuItem> */}
                                    <MenuItem><ActivityStartTime activity={activity} from={"ellipsis"} disableEllipsis={disableEllipsis} setDisableEllipsis={setDisableEllipsis} closeEllipsis={setOpen} /></MenuItem>
                                    <MenuItem><ActivityDuration activity={activity} from={"ellipsis"} disableEllipsis={disableEllipsis} setDisableEllipsis={setDisableEllipsis} closeEllipsis={setOpen} /></MenuItem>
                                    {!isFirst && <MenuItem>
                                        <div style={{ cursor: "pointer", textAlign: "right", color: "#191919", font: "14px/18px Futura Md BT" }}
                                            onClick={moveUp}
                                        ><ArrowUpward width={16} height={16} style={{ marginRight: "0px", marginBottom: "3px", marginRight: "10px" }} /> Up 1 day
                                        </div>
                                    </MenuItem>}
                                    {!isLast && <MenuItem>
                                        <div style={{ cursor: "pointer", textAlign: "right", color: "#191919", font: "14px/18px Futura Md BT" }}
                                            onClick={moveDown}
                                        ><ArrowDownward width={16} height={16} style={{ marginRight: "0px", marginBottom: "3px", marginRight: "10px" }} /> Down 1 day
                                        </div>
                                    </MenuItem>}

                                    <div className={classes.hr}></div>
                                    <MenuItem><DeleteActivity activity={activity} tripDestinationId={activity.tripDestinationId} fetchingStatus={false} from={"ellipsis"} closeEllipsis={setOpen} /></MenuItem>
                                </MenuList>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </ClickAwayListener>

        </div>
    )
}


const mapStateToProps = state => {
    return {
        is_mobile: state.Setting.is_mobile,
        tripMode: state.Member.tripMode,
        activeTripActivities: state.FormBank.TimelineWizard.activeTrip.activities,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    isFetchingEstablishments: state.FormBank.TimelineWizard.isFetchingEstablishments,
    switch_container: state.Member.switch_container,
    }
}

const mapDispatchToProps = {
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
}


const TripEllipsisTimeline = connect(mapStateToProps, mapDispatchToProps)(TripEllipsisTimeline_)
export default TripEllipsisTimeline