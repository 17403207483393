import React from 'react'
import { connect } from 'react-redux'
import EstablishmentMap from './EstablishmentMap'
import {
    EnvelopeIcon,
    InfoCircleIcon,
    MapMarkerIcon,
    WebsiteIcon,
} from '../Common/SVGBank'
import { getHotelMapImage, getHotelPlannerID, testHotelPlanner } from './hotelServices'
import { useParams } from 'react-router';


const About_ = ({ establishment }) => {
    const { generalInfo } = establishment
    const address = generalInfo.address !== undefined && generalInfo.address !== null && generalInfo.address !== "" ? generalInfo.address + ", " : ""
    const country = generalInfo.countryName !== undefined && generalInfo.countryName !== null && generalInfo.countryName !== "" ? generalInfo.countryName + " " : ""
    const postalCode = generalInfo.postalCode !== undefined && generalInfo.postalCode !== null && generalInfo.postalCode !== "" ? generalInfo.postalCode : ""
    const city = generalInfo.cityName !== undefined && generalInfo.cityName !== null && generalInfo.cityName !== "" ? generalInfo.cityName + ", " : ""
    const state = generalInfo.stateName !== undefined && generalInfo.stateName !== null && generalInfo.stateName !== "" ? generalInfo.stateName + ", " : ""

    const [hotelMapUrl, setMapUrl] = React.useState();
    const locationURL = generalInfo !== undefined && generalInfo.address !== undefined && generalInfo.address !== null && generalInfo.address !== ""
        && generalInfo.countryName !== undefined && generalInfo.countryName !== null && generalInfo.countryName !== ""
        ? (address + city + state + country + postalCode).replace(" ", "+") : ""

    const params = useParams()
    const establishmentPricelineID = params?.establishmentPricelineID;


    React.useEffect(() => {

        if(establishment){
            loadHotelMapImage()
        }
    }, [establishment])


    const loadHotelMapImage = React.useCallback(async () => {
        const establishmentID = establishment?.generalInfo?.establishmentPricelineID
        if (establishmentID) {
            const result = await getHotelMapImage(establishmentID);

            if (result) {
                setMapUrl(result)
            }
        }
    }, [establishment])



    React.useEffect(() => {
        getHotelPlannerID(establishmentPricelineID)
    }, [])


    function checkLink(link) {
        if (typeof link === 'string' && link != null && link.length > 0) {
            if (link.slice(0, 4) == "http") {
                return link;
            } else {
                return ("https://" + link);
            }
        } else {
            return;
        }
    }

    const establishmentWebsite = checkLink(generalInfo.website)

    return (
        <div style={{ minHeight: 460 }}>
            <div style={{ font: "24px/36px Futura Hv BT", color: "#000000", marginBottom: "30px", }}>About this hotel</div>
            <div>
                <div style={{ float: "right", width: "50%", height: "auto", marginLeft: "2rem", marginRight: "2rem", marginBottom: "2rem" }}>
                    {/*<div style={{ flexGrow: "1", marginLeft: is_mobile ? "" : '300px' }}>*/}
                    {window.google !== undefined && generalInfo.latitude !== "" && generalInfo.latitude !== null &&
                        generalInfo.longitude !== null &&
                        generalInfo.longitude !== "" &&

                        <div onClick={() => window.open('https://www.google.com/maps/place/' + locationURL + '/@' + establishment.generalInfo.latitude + ',' + establishment.generalInfo.longitude)}>
                            <img
                                src={hotelMapUrl} alt={""} width="100%" height={300}
                                style={{
                                    opacity: '0.8', width: "100%", objectFit: "cover", objectPosition: "center",
                                    filter: "grayscale(1)"
                                    //paddingLeft: "15%", paddingRight: "15%"
                                }} />

                        </div>}
                    {/* 
                     <EstablishmentMap lat={generalInfo.latitude} lng={generalInfo.longitude} /> */}
                </div>
                <div style={{ font: "16px/22px Futura Lt BT", color: "#929292" }}

                    dangerouslySetInnerHTML={{ __html: generalInfo !== null && generalInfo.about }}>
                </div>

                {generalInfo?.address && <div>
                    <div style={{ font: "18px/22px Futura Hv BT", color: "#000000", marginTop: "10px", marginBottom: "5px", marginTop: 10 }}>
                        Hotel Address
                    </div>
                    <div style={{ font: "16px/22px Futura Lt BT", color: "#929292", marginBottom: "15px", marginTop: 10 }}>
                        {address + city + state + country + postalCode}
                    </div>
                </div>}
                <div>

                    {generalInfo.phone !== undefined && generalInfo.phone !== null && generalInfo.phone !== "" && <div style={{ font: "16px/22px Futura Md BT", color: "#929292", marginBottom: "15px", }}><InfoCircleIcon fill={"#929292"} style={{ marginRight: "25px", marginBottom: "3px" }} />{generalInfo.phone}</div>}
                    {generalInfo.website !== undefined && generalInfo.website !== null && generalInfo.website !== "" && <div style={{ font: "16px/22px Futura Md BT", color: "#929292", marginBottom: "15px", }}><WebsiteIcon fill={"#929292"} style={{ marginRight: "25px", marginBottom: "3px" }} /><a href={establishmentWebsite} target="_blank" rel="noopener noreferrer">Website</a></div>}
                    {generalInfo.email !== undefined && generalInfo.email !== null && generalInfo.email !== "" && <div style={{ font: "16px/22px Futura Md BT", color: "#929292", marginBottom: "15px", }}><EnvelopeIcon fill={"#929292"} style={{ marginRight: "25px", marginBottom: "3px" }} />{generalInfo.email}</div>}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

    establishment: state.FormBank.HotelSearch.establishment,
    isMobile: state.Setting.is_mobile,
    lang: state.Setting.lang,
    uuid: state.Member.authModal.uuid,

    switchFeatureContainer: state.FormBank.FeatureFormat,
})

const mapDispatchToProps = {

}

const About = connect(mapStateToProps, mapDispatchToProps)(About_)
export default About
