import * as Actions from '../../../redux/actions'
import ReactDOMServer from 'react-dom/server';

import { InfoWindow, Marker } from "react-google-maps"

import { EstablishmentDescription } from "./EstablishmentDescription"
import EstablishmentName from './Establishment/EstablishmentName'
import EstablishmentNamePriceline from './HotelEstablishment/EstablishmentName'
import {
    Image,
} from 'react-bootstrap'
import { PlusSignIcon, StarIcon } from './SVGBank'
import Rating from '@material-ui/lab/Rating'
import React from 'react'
import { connect } from 'react-redux'
import { image_boxing, priceline_image_boxing, member_area_router, map_type_icon_components, generateHQHotelImages } from './RegExValidate'
import FeatureTypeIcon from './FeatureTypeIcon'
import { environment } from '../../../Environments/environment';
import Axios from 'axios';
import moment from 'moment';
import { saveHotelTimeline } from '../BookingPage/PaymentPage/payment.thunk';
import { getPropertyType } from '../../../redux/actions';

export const HotelPlacesMarkers_ = ({ hotelSearchForm, saveHotelTimelineTrip, pushPlacesWindow, clearPlacesWindow, checkWindowOpen, icon, establishment, zoom, switchFeatureContainer, switch_container, addActivityToTripDestinationInTrip, activeTripDay, index_establishment, uuid_auth, lang, activeTrip, activeTripDestinationId, placesNearByCenter, activeDestinationId }) => {

    const [establishmentImage, setEstabImage] = React.useState()
    const uuid = uuid_auth !== "" ? uuid_auth : "TMPUUID"
    const activeTripId = activeTrip.tripId
    const activeDayInTripDestination = activeTripDay.dayInTripDestination

    var timer;


    const switchFeatureContainerColorLight = switchFeatureContainer.color + "35"
    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === activeTripDay.dayInTrip && a.tripDestinationId === activeTripDay.tripDestinationId)
    const lengthActivitiesInDestinationDay = activitiesInDestinationDay !== undefined ? activitiesInDestinationDay.length : 0
    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
    const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""

    const activeTripDestinationIds = Array.from(new Set(activeTrip.tripDestinations.map(d => d.id)))
    const tripDuration = activeTrip.tripDuration

    let clearWindowTimeout;








    const toggleOpenInfoWindow = React.useCallback(() => {
        if (!checkWindowOpen(establishment?.establishmentPricelineID)) {
            if (establishment && establishment.establishmentPricelineID) {


                timer = setTimeout(() => {
                    pushPlacesWindow(establishment.establishmentPricelineID)

                }, 500);
                // pushPlacesWindow(establishment.id)

            }

        }
        else {
            clearPlacesWindow()
        }


    }, [establishment])



    const mouseOutMarker = () => {
        clearTimeout(timer)
        clearPlacesWindow()

    }



    const removeHoverOpenInfoWindow = () => {
        clearTimeout(timer);
        clearWindowTimeout = setTimeout(() => {
            clearPlacesWindow()
        }, 8000)
        //    clearTimeout(clearWindowTimeout)
    }



    const infoWindowRef = React.useRef(null);

    const handleAddActivityToTripDestinationInTrip = (lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds) => {
        let activeMapTripDestinationId = activeTripDestinationId
        let activeDayMapInTripDestination = activeDayInTripDestination
        let activeDayMapDayInTrip = activeTripDay.dayInTrip
        const isSetActiveTrip = destinationId !== activeDestinationId
        if (isSetActiveTrip) {
            const existDestinationArray = activeTrip.tripDestinations.filter(d => d.id === String(destinationId))
            const existDestinationArrayLength = existDestinationArray !== undefined ? existDestinationArray.length : 0
            const exsitDestination = existDestinationArray !== undefined ? existDestinationArray[existDestinationArrayLength - 1] : undefined
            activeMapTripDestinationId = exsitDestination === undefined ? activeTripDestinationId : exsitDestination.td_id
            const destinationDays = exsitDestination !== undefined && exsitDestination.spanningDays !== undefined ? exsitDestination.spanningDays.split(',') : undefined
            const destinationDaysLength = destinationDays !== undefined ? destinationDays.length : 0
            activeDayMapInTripDestination = exsitDestination === undefined ? activeDayInTripDestination : destinationDaysLength
            activeDayMapDayInTrip = exsitDestination === undefined ? activeTripDay.dayInTrip : destinationDays[destinationDaysLength - 1]
        }

        addActivityToTripDestinationInTrip(lang, uuid, activeMapTripDestinationId, activeDayMapInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds, isSetActiveTrip, activeDayMapDayInTrip)
        document.getElementById(activeMapTripDestinationId + "_" + activeDayMapInTripDestination + "_" + activeDayMapDayInTrip) !== null && document.getElementById(activeMapTripDestinationId + "_" + activeDayMapInTripDestination + "_" + activeDayMapDayInTrip).scrollIntoView({ behavior: 'smooth', block: 'start' })

    }

    const [infoWindowPosition, setInfoPosition] = React.useState(0)

    const infoWindowPositionChanged = () => {

    }

    const TypeIcon = ReactDOMServer.renderToString(<FeatureTypeIcon
        icon={icon}
        fill={'white'}
        style={{
            width: "15px",
            height: "16px",
            marginLeft: "5px",
            marginRight: "10px",
        }} />)

    const RatingIcon = ReactDOMServer.renderToString(
        <StarIcon fill="var(--mainGreen)" width="15px" height="15px" style={{ marginRight: "5px" }} />
    )
    const imageSrc = establishment?.estabPicture ? generateHQHotelImages(establishment?.estabPicture) : "";

    const handleAddHotelToTripDestinationInTrip = React.useCallback((establishment, hotel_form_st, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishmentID, activeTripId, last_establishmentId, tripDuration, destinationId, activeTripDestinationIds) => {
        //console.log("handleAddHotelToTripDestinationInTrip hotel_form_st ",hotel_form_st);
        //console.log("handleAddHotelToTripDestinationInTrip hotelSearchForm ",hotelSearchForm);
        const lengthOfStay = moment(hotelSearchForm.check_out).diff(moment(hotelSearchForm.check_in), "days")
        const establishmentPricelineID = establishment.establishmentPricelineID
        saveHotel(establishmentPricelineID, hotelSearchForm.check_in, hotelSearchForm.check_out)
    }, [establishment])


    const saveHotel = (hotelId, checkIn, checkOut) => {
        const saveData = {
            postData: {
                pricelineHotelId: hotelId || "",
                booking_trip_number: "",
                booking_status: "",
                booking_status_code: "",
                checkIn: checkIn || "",
                checkOut: checkOut || ""
            },
            uuid: uuid || null
        }
        saveHotelTimelineTrip(saveData);

    }




    const propertyType = establishment?.propertyType && getPropertyType(establishment?.propertyType)?.property_type_name ? getPropertyType(establishment?.propertyType)?.property_type_name : "";

    const starRating = establishment?.establishmentStarRating ? Number(establishment?.establishmentStarRating) : 0;
    const isIntegerRating = Number?.isInteger(starRating);
    const longerPrices = String(Number(establishment?.display_price)?.toFixed(0))?.length > 3;

    return (
        <div
            onMouseLeave={mouseOutMarker}>
            <Marker
                zIndex={2}
                position={{
                    lat: parseFloat(establishment.latitude),
                    lng: parseFloat(establishment.longitude)
                }}
                options={{
                    optimized: false
                }}
                onMouseOver={() => toggleOpenInfoWindow()}
                onMouseOut={() => clearTimeout(timer)}
                onClick={() => toggleOpenInfoWindow()}
                icon={{
                    url:
                        `data:image/svg+xml;utf8,
                        ${longerPrices?
                            `<svg  xmlns="http://www.w3.org/2000/svg" width="115" height="48" 
                            viewBox="0 0 115 45" >` 
                            : isIntegerRating ? `<svg  xmlns="http://www.w3.org/2000/svg" width="100" height="48" 
                        viewBox="0 0 100 45" >`: `<svg  xmlns="http://www.w3.org/2000/svg" width="110" height="48" 
                        viewBox="0 0 110 45" >`}
                        <style>
.whiteText {
 margin-left: 30px;
 fill: rgb(39, 70, 116);
font: 10px/12px Futura Md BT !important,
font-family:Roboto-Regular,Roboto;
}

.greenText {
    fill: rgb(25, 188, 155);
}

.wrapperPrice{
    stroke-width: 2px;
    stroke: rgba(13, 58, 125, 1);
    fill:white;
    stroke-dasharray: 6 3;
      
}

</style>
                ${longerPrices?
                    ` <rect x="5" y="5" rx="20"  width="105" height="35" fill="white" class="wrapperPrice"></rect>`
                    : isIntegerRating ? `<rect x="5" y="5" rx="20"  width="95" height="35" fill="white" class="wrapperPrice"></rect>` :
                            ` <rect x="5" y="5" rx="20"  width="105" height="35" fill="white" class="wrapperPrice"></rect>`}
                    <g  fill-rule="evenodd">    
                        <circle cx="22.5" cy="22.5" r="12" fill="rgba(13, 58, 125, 1)" />
                       
                    </g>
                    <svg  width="45" height="45"  viewBox="8 -22 1 60" >
                    ${TypeIcon}
                    </svg>
                    <text class="whiteText" font-size="14"  font-family="Arial, Helvetica, sans-serif"  font-weight="600"  x="40" y="26" >${true ? "$" + Number(establishment?.display_price)?.toFixed(0) : ""}</text>
                    ${longerPrices? `<text  font-size="14"   class="greenText" font-family="Arial, Helvetica, sans-serif"  font-weight="600"  x="82" y="26" >${starRating ?? 0}*</text>`:
                    `<text  font-size="14"   class="greenText" font-family="Arial, Helvetica, sans-serif"  font-weight="600"  x="75" y="26" >${starRating ?? 0}*</text>`}
                  
</svg>`   ,
                    // size: { width: 150, height: 45 },
                    // anchor: {x: 0, y: 0},
                    scaledSize: { width: zoom ? zoom * 15 : 150, height: zoom ? zoom * 3 : 45 },
                }}

            >
                {(checkWindowOpen(establishment?.establishmentPricelineID)) &&
                    <InfoWindow ref={infoWindowRef}
                        onPositionChanged={infoWindowPositionChanged}
                        options={{
                            disableAutoPan: true, zIndex: 200,
                            pixelOffset: new window.google.maps.Size(-4, 5)
                        }}
                        onCloseClick={() => toggleOpenInfoWindow()}>
                        <div style={{ marginBottom: "10px" }}>

                            {imageSrc && <Image className="img_fit_cover"
                                src={imageSrc}
                                alt="establishment" width={260} height={130} />}


                            {<div style={{ padding: "10px 0px 0px 10px" }}>
                                {establishment?.establishmentReviewRating > 0 && <div className="timeline_rating_color"
                                    style={{ textAlign: "left", paddingBottom: 5 }}>
                                    <div
                                        style={{
                                            font: "12px/12px Futura Lt BT",
                                            color: "var(--mainBlue)",

                                        }}>Guest rating: {establishment?.establishmentReviewRating}</div>

                                </div>}
                                <div style={{ font: "12px/12px Futura Hv BT", color: "var(--mainGreen)", }}>
                                    {establishment?.neighborhood?.name || establishment?.hotel_zone}
                                </div>



                            </div>}


                            <div style={{ display: "flex", padding: "0px 10px 0px 10px", width: "260px" }}>
                                <div style={{ width: "90%", marginTop: 10, }}>
                                    <EstablishmentNamePriceline
                                        establishmentCity={establishment?.establishmentCity}
                                        establishmentState={establishment?.establishmentState}
                                        establishmentCountry={establishment?.establishmentCountry}
                                        establishmentName={establishment?.establishmentName}
                                        establishmentId={establishment?.establishmentPricelineID}
                                        pricelineId={establishment.establishmentPricelineID}
                                        ppn_bundle={establishment.ppn_bundle}
                                        titleClass={"box_1"}
                                        titleStyle={{ cursor: 'pointer', font: "14px/16px Futura Hv BT", textAlign: "left", color: "var(--frameBlack)", }}
                                    />

                                </div>

                                {establishment?.establishmentStarRating !== undefined && establishment?.establishmentStarRating !== "0.0" && <div className="timeline_rating_color"
                                    style={{ width: "10%", paddingTop: 8, textAlign: "right", display: "flex" }}>
                                    <div
                                        style={{
                                            font: "10px/12px Futura Md BT",
                                            color: "var(--mainGreen)",
                                            paddingTop: 3

                                        }}>{establishment.establishmentStarRating}</div>
                                    <div style={{
                                        paddingRight: "2px",
                                    }}>
                                        <Rating name="timeline_rating" size={"small"} precision={0.5} max={1} value={parseFloat((establishment.establishmentStarRating / 5).toFixed(1))} disabled={true} />
                                    </div>
                                </div>}
                            </div>
                            {establishment?.establishmentDescription !== "" && <div style={{ textAlign: "left", padding: "10px 10px 0px 10px", width: "260px" }}>
                                <EstablishmentDescription description={establishment?.establishmentDescription} boxDescription={"box_2"}
                                    bottom={'0px'} fontDescription={"14px/15px Futura Lt BT"} colorDescription={"var(--mainGrey)"} height={"60px"}
                                    textLength={100}
                                />
                            </div>}


                            <div style={{ display: "flex", textAlign: "left", padding: "0px 10px", width: "260px" }}>
                                <div style={{ flexGrow: "1", }}>
                                    {establishment.subType !== undefined && establishment.subType !== null
                                        && establishment.subType.subTypeName !== undefined && <div style={{ flexGrow: "1" }}>
                                            <div className="btn_rounded" style={{
                                                font: "10px/13px Futura Hv BT",
                                                marginTop: "10px",
                                                color: switchFeatureContainer.color,
                                                backgroundColor: switchFeatureContainerColorLight,
                                                borderColor: switchFeatureContainerColorLight,


                                            }}>{establishment.subType.subTypeName}</div>
                                        </div>}
                                </div>
                                {activeTripId !== "" &&
                                    <div style={{ marginTop: "10px" }}>

                                        <div onClick={() => handleAddHotelToTripDestinationInTrip(establishment, hotelSearchForm, lang, uuid, activeTripDestinationId, activeDayInTripDestination, establishment.establishmentPricelineID, activeTripId, last_establishmentId, tripDuration, activeDestinationId, activeTripDestinationIds)} style={{ cursor: "pointer", color: "var(--mainGreen)", font: "10px/13px Futura Hv BT", padding: "5px 0px", display: "inline-block" }}><PlusSignIcon width={10} height={13} style={{ marginRight: "5px" }} />Add to trip</div>
                                    </div>}

                            </div>

                            <div style={{ paddingLeft: 5, float: "left", marginBottom: 10 }}>
                                <div className="btn_rounded m-1 futura_md_bt_14_16_green upcoming_trip_style" style={{
                                }}>
                                    {propertyType}
                                </div>
                            </div>

                            {establishment?.display_price && <div
                                style={{ marginTop: 5, textAlign: "right", paddingRight: 10 }}>
                                <div
                                    style={{
                                        font: "12px/12px Futura Md BT",
                                        color: "var(--mainBlue)",

                                    }}>from {establishment?.display_symbol || "$"}<strong>{Number(establishment?.display_price)?.toFixed(2)}</strong></div>

                            </div>}

                        </div>
                    </InfoWindow>}
            </Marker >
        </div>
    )
}

const mapStateToProps = (state) => ({
    lang: state.Setting.lang,
    uuid_auth: state.Member.authModal.uuid,
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
    activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
    activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
    activeTripDay: state.FormBank.TimelineWizard.activeDay,
    destinationId: state.Destination.destination.activeId,
    switchFeatureContainer: state.FormBank.FeatureFormat,
    switch_container: state.Member.switch_container,
    hotelSearchForm: state.FormBank.HotelSearch.hotel_form_state,
})

const mapDispatchToProps = {
    addActivityToTripDestinationInTrip: Actions.addActivityToTripDestinationInTrip,
    deleteActivityToDayOfTripDestinationInTrip: Actions.deleteActivityToDayOfTripDestinationInTrip,
    saveHotelTimelineTrip: saveHotelTimeline,
}

const HotelPlacesMarkers = connect(mapStateToProps, mapDispatchToProps)(HotelPlacesMarkers_)
export default HotelPlacesMarkers
