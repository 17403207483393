import { environment } from "../../../Environments/environment";
import { PostAsyncHelper } from "../../../redux/interfaces/types";

export const getBookingFromText = async (payload)=>{
    try{
       const url =   environment.apiUrl +'muv-ai/getBookingFromText';
       const response = await PostAsyncHelper(url, payload);
   
        if(response?.data?.status === "success"){
            return response?.data?.result;
        }
        return false;
    }
    catch{
       return false;
      
   
    }
   }