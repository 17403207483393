import ActivitiesDay from './ActivitiesDay'
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

const ActivitiesDestinationDays_ = ({ destination, activeTrip }) => {

    const tripStartDate = activeTrip.tripStartDate
    const tripNoDate = activeTrip.tripNoDate
    const destinationDays = destination.spanningDays !== undefined && destination.spanningDays.split(',')

    let activitiesRef = React.createRef(null)

    const lengthDestinationDays = destinationDays.length
    return (
        <div style={{
            backgroundColor: "var(--mainWhite)"
        }}
        ref={activitiesRef}
        onDragEnter={()=>{
        //    const x = activitiesRef.current?.scrollIntoView({ behavior: 'smooth' })
        }}
        
        >
            {destination.spanningDays !== undefined && destination.spanningDays !== ""
                && destinationDays.map((dayInTrip, index_day) => {
                    const dayInTripDestination = String(index_day + 1)
                    const startingDate = tripNoDate === "1" ? "Day " + dayInTripDestination
                        : moment(tripStartDate).add(dayInTrip - 1, "days").format('dddd, MMMM D')
                    const startingDateInTrip = tripNoDate === "1" ? "Day " + dayInTrip + " in the trip"
                        : ""

                    const lengthActivitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === dayInTrip && a.tripDestinationId === destination.td_id).length
                    const activitiesInDestinationDay = activeTrip.activities.filter(a => a.activityDay === dayInTrip && a.tripDestinationId === destination.td_id)
                    const activitiesInDestinationDayIds = Array.from(new Set(activitiesInDestinationDay.map((a) => a.activityID)))
                    const activitiesInDestinationDayEstablishmentIds = Array.from(activitiesInDestinationDay.map((a) => a.activityEstabID))
                    const last_establishmentId = activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDayEstablishmentIds[lengthActivitiesInDestinationDay - 1] : ""
                    const last_activity = activitiesInDestinationDay[lengthActivitiesInDestinationDay - 1] !== undefined ? activitiesInDestinationDay[lengthActivitiesInDestinationDay - 1] : ""
                    return (
                        <div key={index_day}
                            style={{
                                margin: "12px",
                                marginRight: '0px',
                                backgroundColor: "var(--mainWhite)",
                            }}>
                            {dayInTrip !== "" && <ActivitiesDay
                                destination={destination}
                                lengthDestinationDays={lengthDestinationDays}
                                last_establishmentId={last_establishmentId}
                                activitiesInDestinationDayIds={activitiesInDestinationDayIds}
                                startingDate={startingDate} startingDateInTrip={startingDateInTrip}
                                dayInTrip={dayInTrip}
                                activitiesInDestinationDay={activitiesInDestinationDay}
                                dayInTripDestination={dayInTripDestination}
                                lengthActivitiesInDestinationDay={lengthActivitiesInDestinationDay}
                                activitiesInDestinationDayEstablishmentIds={activitiesInDestinationDayEstablishmentIds}
                                lastActivity={last_activity}
                            />}
                        </div>
                    )
                })}
        </div >
    )
}

const mapStateToProps = (state) => ({
    activeTrip: state.FormBank.TimelineWizard.activeTrip,
})


const ActivitiesDestinationDays = connect(mapStateToProps, null)(ActivitiesDestinationDays_)
export default ActivitiesDestinationDays 
