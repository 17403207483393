import * as Actions from '../../../redux/actions'

import { AngleRightIcon } from "../Common/SVGBank"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import React from 'react'
import { URL_REPLACE } from "../Common/RegExValidate"
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { member_area_router } from "../Common/RegExValidate"
import { useHistory } from "react-router-dom"

const Breadcrumb_ = ({
    establishment,
    isMobile,
    lang,
    switchFeatureContainer,
    setPageYOffset,
    setSwitchContainer,
}) => {
    const history = useHistory()
    const goesToDestinationPage = (id, title) => {
        // console.log(id, title)
        setSwitchContainer(member_area_router.destination)
        const url ="/destination/" + title.replace(URL_REPLACE, "-") + "/" + 1 + "/" + id;
        history.push(url.toLowerCase())
        setPageYOffset(0)
    }
    const goesToHomePage = () => {
        setSwitchContainer(member_area_router.home)
        history.push("/")
    }

    const breadsrumbsUseStyles = makeStyles(theme => ({
        root: {
            '& .MuiSvgIcon-root': {
                color: switchFeatureContainer.color,
                backgroundColor: "rgb(25, 188, 155, 0.2)",
            },
        },
    }));
    const classes = breadsrumbsUseStyles()
    const { breadcrumb, generalInfo, } = establishment

    let fontSize = "16"
    if (!isNaN(isMobile)) {
        fontSize = isMobile ? "9" : "16"
    }
    return (
        <div className="d-flex flex-wrap"
            style={{
                paddingTop: "10px",
                paddingBottom: "10px",
            }}
        >
            {generalInfo !== undefined && <div style={{
                font: fontSize + "px/19px Futura Md BT",
                letterSpacing: "0",
                paddingLeft: "10px",
                paddingRight: "10px",
                flexGrow: "1",
                color: switchFeatureContainer.color,
                wordWrap: "break-word",
            }}>

                <Breadcrumbs maxItems={8} separator={<AngleRightIcon fill={switchFeatureContainer.color} />} className={classes.root}>
                    <div className="d-inline-block" style={{
                        color: switchFeatureContainer.color,
                        cursor: "pointer",
                        font: fontSize + "px/19px Futura Md BT",
                    }}
                        onClick={() => goesToHomePage()}
                    > {'Home'}</div>
                    {
                        breadcrumb !== undefined && breadcrumb.length > 0
                        && breadcrumb.map((destination, idx) => {
                            return (
                                <div key={idx}
                                    className="d-inline-block"
                                    style={{
                                        font: fontSize + "px/19px Futura Md BT",
                                        cursor: "pointer",
                                        color: switchFeatureContainer.color,
                                    }}
                                    onClick={() => goesToDestinationPage(destination.id, destination.title)}>
                                    {destination.title}
                                </div>

                            )
                        })
                    }
                    <div className="d-inline-block" style={{
                        color: switchFeatureContainer.color,
                    }}
                    > {generalInfo !== undefined && generalInfo !== null && generalInfo.name}</div>
                </Breadcrumbs>
            </div>}
            {breadcrumb !== undefined && breadcrumb.length > 0 && breadcrumb[breadcrumb.length - 1] !== undefined && <div style={{
                font: (Number(fontSize) + 1) + "px/19px Futura Md BT",
                letterSpacing: "0",
                color: switchFeatureContainer.color,
                paddingLeft: "10px",
                paddingRight: "60px",
                wordWrap: "break-word",
                cursor: "pointer",
                zIndex: "2",
                backgroundColor: "var(--mainWhite)",
            }}
                onClick={() => goesToDestinationPage(breadcrumb[breadcrumb.length - 1].id, breadcrumb[breadcrumb.length - 1].title)}
            >
                {generalInfo !== undefined && generalInfo !== null && "Back to list"}
            </div>}
        </div>
    )
}


const mapStateToProps = state => {
    return {
        establishment: state.Destination.establishment,
        isMobile: state.Setting.is_mobile,
        lang: state.Setting.lang,
        uuid: state.Member.authModal.uuid,

        switchFeatureContainer: state.FormBank.FeatureFormat,
    }
}

const mapDispatchToProps = {
    setPageYOffset: Actions.setPageYOffset,
    setSwitchContainer: Actions.setSwitchContainer,
}

const Breadcrumb = connect(mapStateToProps, mapDispatchToProps)(Breadcrumb_)
export default Breadcrumb
