import * as Actions from '../../../../redux/actions'

import { CalendarDayDuotoneIcon, EllipsisVIcon, ListULIcon, MapDuotoneIcon, PrintIcon, TimelineIcon, TimesIcon } from "../SVGBank"

import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Collapse from '@material-ui/core/Collapse'
import CopyTripDialog from "../CopyTripDialog"
import DeleteDialog from "../DeleteDialog"
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ModifyTripDateDialog from "../ModifyTripDateDialog"
import ModifyTripNameDialog from "../ModifyTripNameDialog"
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import ShareTripDialog from "../ShareTripDialog"
import { connect } from 'react-redux'
import { list_container_mode, URL_REPLACE } from '../RegExValidate'
import { makeStyles } from '@material-ui/core/styles'
import { member_area_router } from '../RegExValidate'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRoute } from '@fortawesome/free-solid-svg-icons'


/*  inject css to menu component */
const useStyles = makeStyles(theme => ({
    root: {
        display: 'inline-block',
    },
    positionTimeline: style_props => ({
        zIndex: "33",
        // top: "-180px !important",
        // left: "15px !important",
        top: "0px !important",
        left: "0px !important",
        [theme.breakpoints.down('md')]: {
            left: "-130px !important",
            top: "0px !important",
        },
       
    }),
    position: style_props => ({
        zIndex: "33",
        top: "-30px !important",
        left: "-100px !important",
        [theme.breakpoints.up('md')]: {
            left: "-125px !important",
        },
        [theme.breakpoints.down('sm')]: {
            left: "-70px !important",
            top: "-30px !important",
        },
    }),
    backgroundSelection: {
        '&:hover': {
            backgroundColor: "var(--mainGreen)"
        },
        minHeight: "10px"
    },
    paper: {
        marginRight: theme.spacing(2),
    },

    hr: {

        width: "65%",
        height: "1px",
        display: "block",
        position: "relative",
        marginRight: "10%",
        marginTop: "5px",
        marginLeft: "25%",
        marginBottom: "5px",
        border: "1px dashed rgba(0,0,0,.1)",
    },
    timesDiv: {
        marginRight: "10px",
        marginLeft: "115px",
        display: "inline-block",
        textAlign: "right",
    },
    hidden: {
        zIndex: -1,
        top: "-5000px !important",
    }



}))
/* menu list composition function component 

param  trip object

*/
const TripEllipsisTimeline_ = ({is_mobile,color, activeTrip, closeTripTimelineWizard, tripMode, switch_container, toggle_ellipsis_close_disable, screenSize, setSwitchContainer, setTripDisplayMode }) => {

    const style_props = { screenSize: Math.min(screenSize, document.body.clientWidth) }
    const history = useHistory()
    const classes = useStyles(style_props)
    const [open, setOpen] = React.useState(false)

    const [openTripAction, setOpenTripAction] = React.useState(false)
    const [openTripInformation, setOpenTripInformation] = React.useState(true)

    const handleEllipsis = (event) => {
        event.preventDefault()
        setOpen(prevOpen => !prevOpen)
    }
    const anchorRef = React.useRef(null)
    const handleClickTripAction = () => {
        setOpenTripAction(!openTripAction)
        setOpenTripInformation(false)
    }
    const handleClickTripInformation = () => {
        setOpenTripInformation(!openTripInformation)
        setOpenTripAction(false)
    }
    const handleClose = event => {
        if (!toggle_ellipsis_close_disable) {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return
            }
            setOpen(false)
        }
    }


    const closeTrip = ()=>{
        if(is_mobile){
            history.push('/')
        }
        closeTripTimelineWizard()
        if(tripMode === list_container_mode.map){
            history.push('/')
        }
    }


    const goToTripPage = () => {
        const url = "/muver/trippage/" + activeTrip?.tripId;

        history.push(url.toLowerCase())
    }

    const handleTripInformation = (from) => {
        const url = `/muver/trip/${from}/${activeTrip.tripId}`;
        history.push(url.toLowerCase())
        setOpen(false)
    }
    const printUrl = "/print-" + activeTrip.tripName.replace(URL_REPLACE, "_") + "-" + activeTrip.tripId;
    const tripNoDate = activeTrip.tripNoDate
    return (
        <React.Fragment>
            {switch_container === member_area_router.memberAreaTrip ? <div className={classes.root}>
                <div
                    ref={anchorRef}
                    style={{ cursor: "pointer", }}
                    onClick={e => handleEllipsis(e)}>
                    <EllipsisVIcon height={'25'} fill={color ? color : "#E8E8E8"} />
                </div>
                <ClickAwayListener onClickAway={handleClose}>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        className={toggle_ellipsis_close_disable ? classes.hidden : classes.positionTimeline}
                        transition
                        disablePortal
                        placement={"bottom-start"}
                        modifiers={{
                            flip: {
                                enabled: false,
                            },
                        }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                            >
                                <Paper>
                                    <MenuList
                                        autoFocusItem={false}
                                        disableListWrap={true}
                                        dense={false}
                                        id="trip_ellipsis_timeline" >
                                        <div className={classes.timesDiv} onClick={handleClose}>{screenSize > 1024 ? <TimesIcon width={20} height={20} /> : <TimesIcon width={15} height={15} />}</div>

                                        <div>
                                            <MenuItem className={classes.backgroundSelection} onClick={handleClickTripInformation}>{openTripInformation ? <ExpandLess /> : <ExpandMore />} {toggle_ellipsis_close_disable ? "" : "Trip Mode"} </MenuItem>
                                            <Collapse in={openTripInformation} timeout="auto" unmountOnExit>
                                                <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.schedule)} ><ListULIcon />{list_container_mode.schedule}</MenuItem>
                                                {tripNoDate === "0" && <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.calendar)}><CalendarDayDuotoneIcon />{list_container_mode.calendar}</MenuItem>}
                                                <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.map)}><MapDuotoneIcon style={{ marginRight: "10px" }}
                                                    fill={"#707070"}
                                                    width={"20"}
                                                    height={"20"} />{list_container_mode.map}</MenuItem>
                                                <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.timeline)}><TimelineIcon style={{ marginRight: "10px" }}
                                                    fill={"#707070"}
                                                    width={"20"}
                                                    height={"20"} />{list_container_mode.timeline}</MenuItem>
                                            </Collapse>
                                        </div>

                                        <div className={classes.hr} ></div>
                                        <MenuItem className={classes.backgroundSelection} onClick={handleClickTripAction}>{openTripAction ? <ExpandLess /> : <ExpandMore />} {toggle_ellipsis_close_disable ? "" : "Trip Action"} </MenuItem>
                                        <Collapse in={openTripAction} timeout="auto" unmountOnExit>
                                            <MenuItem className={classes.backgroundSelection} ><ModifyTripDateDialog from={"Start Date"} inline={false} /></MenuItem>
                                            <MenuItem className={classes.backgroundSelection} ><ModifyTripNameDialog from={"Trip Name"} inline={false} /></MenuItem>
                                            <MenuItem className={classes.backgroundSelection} ><CopyTripDialog from={"Copy Trip"} inline={false} /></MenuItem>
                                            <MenuItem className={classes.backgroundSelection}>
                                                <Link to={printUrl} target="_blank" style={{ color: 'black' }}>
                                                    <PrintIcon /> {"Print Trip"}
                                                </Link>
                                            </MenuItem>
                                            <MenuItem className={classes.backgroundSelection} ><ShareTripDialog from={"Share Trip"} inline={false} /></MenuItem>
                                        </Collapse>
                                        <div className={classes.hr}></div>
                                        <MenuItem className={classes.backgroundSelection} onClick={closeTrip}>
                                            <TimesIcon width={15} height={15} fill="#707070" style={{ marginRight: 15 }} />
                                            {"Close Trip"}</MenuItem>
                                        <MenuItem className={classes.backgroundSelection} ><DeleteDialog from={"Delete Trip"} setOpenTripEllipsis={setOpen} /></MenuItem>
                                    </MenuList>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>

                </ClickAwayListener>
            </div> :
                <div className={classes.root}>
                    <div
                        ref={anchorRef}
                        style={{ cursor: "pointer", }}
                        onClick={e => handleEllipsis(e)}>
                        <EllipsisVIcon height={'25'} fill={color ? color : "#FFFFFF"} />
                    </div>
                    <ClickAwayListener onClickAway={handleClose}>
                        <Popper
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            className={toggle_ellipsis_close_disable ? classes.hidden : ((tripMode === list_container_mode.timeline || tripMode === list_container_mode.map) ? classes.positionTimeline : classes.position)}
                            transition
                            disablePortal
                            placement={"bottom-start"}
                            modifiers={{
                                flip: {
                                    enabled: false,
                                },
                            }}
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                                >
                                    <Paper>
                                        <MenuList
                                            autoFocusItem={false}
                                            disableListWrap={true}
                                            dense={false}
                                            id="trip_ellipsis_timeline" >
                                            <div className={classes.timesDiv} onClick={handleClose}>{screenSize > 1024 ? <TimesIcon width={20} height={20} /> : <TimesIcon width={15} height={15} />}</div>

                                            <div>
                                                <MenuItem className={classes.backgroundSelection} onClick={handleClickTripInformation}>{openTripInformation ? <ExpandLess /> : <ExpandMore />} {toggle_ellipsis_close_disable ? "" : "Trip Mode"} </MenuItem>
                                                <Collapse in={openTripInformation} timeout="auto" unmountOnExit>
                                                    <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.schedule)} ><ListULIcon />{list_container_mode.schedule}</MenuItem>
                                                    {tripNoDate === "0" && <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.calendar)}><CalendarDayDuotoneIcon />{list_container_mode.calendar}</MenuItem>}
                                                    <MenuItem className={classes.backgroundSelection} onClick={() => handleTripInformation(list_container_mode.map)}><MapDuotoneIcon style={{ marginRight: "10px" }}
                                                        fill={"#707070"}
                                                        width={"20"}
                                                        height={"20"} />{list_container_mode.map}</MenuItem>
                                                    <MenuItem className={classes.backgroundSelection} onClick={goToTripPage}>
                                                        <FontAwesomeIcon icon={faRoute} fill={"#707070"}
                                                            width={20} height={23} style={{ color: "#707070", height: 23,marginRight:10, }} />{list_container_mode.itinerary}</MenuItem>
                                                </Collapse>
                                            </div>

                                            <div className={classes.hr} ></div>
                                            <MenuItem className={classes.backgroundSelection} onClick={handleClickTripAction}>{openTripAction ? <ExpandLess /> : <ExpandMore />} {toggle_ellipsis_close_disable ? "" : "Trip Action"} </MenuItem>
                                            <Collapse in={openTripAction} timeout="auto" unmountOnExit>
                                                <MenuItem className={classes.backgroundSelection} ><ModifyTripDateDialog from={"Start Date"} inline={false} /></MenuItem>
                                                <MenuItem className={classes.backgroundSelection} ><ModifyTripNameDialog from={"Trip Name"} inline={false} /></MenuItem>
                                                <MenuItem className={classes.backgroundSelection} ><CopyTripDialog from={"Copy Trip"} inline={false} /></MenuItem>
                                                <MenuItem className={classes.backgroundSelection}>
                                                    <Link to={printUrl} target="_blank" style={{ color: 'black' }}>
                                                        <PrintIcon /> {"Print Trip"}
                                                    </Link>
                                                </MenuItem>
                                                <MenuItem className={classes.backgroundSelection} ><ShareTripDialog from={"Share Trip"} inline={false} /></MenuItem>

                                            </Collapse>
                                            <div className={classes.hr}></div>
                                            <MenuItem className={classes.backgroundSelection} onClick={closeTrip}>
                                                <TimesIcon width={15} height={15} fill="#707070" style={{ marginRight: 15 }} />
                                                {"Close Trip"}</MenuItem>
                                            <MenuItem className={classes.backgroundSelection} ><DeleteDialog  from={"Delete Trip"} setOpenTripEllipsis={setOpen} /></MenuItem>
                                        </MenuList>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>

                    </ClickAwayListener>
                </div>
            }
        </React.Fragment >
    )
}


const mapStateToProps = state => {
    return {
        screenSize: state.Setting.htmlBody.bodyClientWidth,
        is_mobile: state.Setting.is_mobile,
        app_menu_height: state.Setting.appMenu.app_menu_height,
        logo_height: state.Setting.appMenu.logo.height,

        toggle_ellipsis_close_disable: state.Member.memberArea.toggle_ellipsis_close_disable,

        activeTrip: state.FormBank.TimelineWizard.activeTrip,
        switch_container: state.Member.switch_container,
        tripMode: state.Member.tripMode,

    }
}

const mapDispatchToProps = {
    setSwitchContainer: Actions.setSwitchContainer,
    setTripDisplayMode: Actions.setTripDisplayMode,
    closeTripTimelineWizard: Actions.closeTripTimelineWizard,
}


const TripEllipsisTimeline = connect(mapStateToProps, mapDispatchToProps)(TripEllipsisTimeline_)
export default TripEllipsisTimeline