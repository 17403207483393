import * as Actions from '../../../../redux/actions'

import {
    DownArrowIcon,
    UpArrowIcon,
} from "../SVGBank"

import FlightFormFlightElement from "./FlightFormFlightElement"
import FlightFormFlightElementRoundTrip from "./FlightFormFlightElementRoundTrip"
import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { resetFlightPage, saveFlight, updateFlightClass, updateFlightData, updateFlightDataItem } from '../../FlightsPage/services/Flights.slice';
import { getFlightsListThunk } from '../../FlightsPage/services/Flights.thunks'
import { useLocation, useHistory } from "react-router-dom";
import { TrackEvent } from '../../../../utility/utility'
import { toggleOneclick } from '../../FeaturePage/personalizedTrip/personalizedTrip.slice'


export const FlightForm_ = ({ lang,setTimelineTripDestinationOpenStatus, uuid,activeTripDestinationId,activeDestinationId, setBookingFormStatus }) => {
    const dispatch = useDispatch()
    const search = useLocation();

    var searchParams = new URLSearchParams(document.location.search.substring(1));
    const cabinClass = useSelector(state => state.Flights.flightData.cabinClass);
    const flightMode = useSelector(state => state.Flights.flightData.flightMode);
    const numberAdult = useSelector(state => state.Flights.flightData.numberAdult);
    const numberChild = useSelector(state => state.Flights.flightData.numberChild);
    const flights = useSelector(state => state.Flights.flightData.flights);
    const selectedCurrency = useSelector(state => state.Setting.currency.selected.shortCode);
    const fullCurrency = selectedCurrency ? selectedCurrency.toUpperCase() : "CAD";
    const [defaultCurrency, setDefaultCurrency] = React.useState(null);


    const [error, setError] = React.useState(false)
    const [showMoreOptionsInMultiCity, setShowMoreOptionsInMultiCity] = React.useState(true)

    const MAX_FLIGHTS = 5



    //relaunch search on currency change
    React.useEffect(() => {
        if (selectedCurrency && defaultCurrency) {
            dispatch(resetFlightPage())
            setDefaultURLData(fullCurrency)
        }
    }, [selectedCurrency])

    React.useEffect(() => {
        try {
            setDefaultCurrency(selectedCurrency)
            setDefaultURLData()

        }
        catch {

        }
    }, [search])


    const setDefaultURLData = (defaultCurrency) => {
        try {
            const flightMode = searchParams.get("flightMode")
            const cabinClass = searchParams.get("cabinClass")
            const numberAdult = searchParams.get("numberAdult")
            const numberChild = searchParams.get("numberChild")
            const flights = searchParams.get("flights")



            if (flights) {
                let flightArray = flights.split(',');

                if (flightArray?.length > 0) {
                    flightArray = flightArray?.map((flight, index) => {
                        const flightInfo = flight?.split("-")
                        const from = flightInfo[0];
                        const to = flightInfo[1];
                        const departure = flightInfo[2];
                        const formatDeparture = departure && !departure?.includes("undefined") ? formatUrlDate(departure) : null;


                        const formattedFlight = {
                            airportFrom: {
                                "city": "",
                                "latitude": "",
                                "city_code": from,
                                "type": "main",
                                "iata": from,
                                "airport": "All Airports"
                            },
                            airportTo: {
                                "city": "",
                                "city_code": to,
                                "type": "main",
                                "iata": to,
                                "airport": "All Airports"
                            },
                            "departureDateFormat": formatDeparture ? new Date(moment(formatDeparture)) : new Date(),
                            "departureDate": formatDeparture ? new Date(moment(formatDeparture)) : new Date(),
                            "availableSelectDate": new Date(),
                            "positionId": "d-" + index
                        }
                        return formattedFlight;
                    })



                    const dataToBookingFlights = {
                        flightMode: flightMode,
                        cabinClass: cabinClass,
                        numberAdult: numberAdult,
                        numberChild: numberChild,
                        flights: flightArray,
                        currency: defaultCurrency ? defaultCurrency : fullCurrency

                    }

                    dispatch(updateFlightData(dataToBookingFlights))
                    dispatch(getFlightsListThunk(dataToBookingFlights)) //run search on load
                }

            }
        }
        catch (e) {

        }


    }

    const formatUrlDate = (date) => {
        return date ? date.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3") : "";
    }


    const handleCabinClassChange = (event) => {
        dispatch(updateFlightDataItem({ type: 'class', value: event.target.value }))
    }
    const handleAdultsChange = (event) => {
        dispatch(updateFlightDataItem({ type: 'adults', value: event.target.value }))
    }
    const handleChildrenChange = (event) => {
        dispatch(updateFlightDataItem({ type: 'children', value: event.target.value }))
    }


    const handleAddFlight = () => {
        const pid_set = ["d-0", "d-1", "d-2", "d-3", "d-4"]
        const used_pid = Array.from(flights.map(d => d.positionId))
        const intersetions = pid_set.filter(pid => used_pid.indexOf(pid) === -1)
        let flightList;


        flightList = [...flights, {
            airportFrom: null,
            airportTo: null,
            departureDateFormat: "",
            departureDate: "",
            availableSelectDate: flights !== undefined
                && flights[flights.length - 1] !== undefined
                && flights[flights.length - 1].departureDate !== ""
                ? new Date(moment(flights[flights.length - 1].departureDate).format("YYYY-MM-DD") + " 00:00") :
                (flights !== undefined
                    && flights[flights.length - 1] !== undefined
                    && flights[flights.length - 1].availableSelectDate !== ""
                    ? new Date(moment(flights[flights.length - 1].availableSelectDate).format("YYYY-MM-DD") + " 00:00") : new Date()),
            positionId: intersetions[0] !== undefined ? intersetions[0] : "",
        }]

        dispatch(updateFlightDataItem({ type: 'flights', value: flightList }))  ///update flights

        setError(false)
    }

    const removeAddedFlight = (flight) => {
        const airport_list = []
        let position_id = flight.positionId
        let isAfter = false;
        let flightList;

        flightList = [...flights.map((f) => {
            if (f.positionId === position_id) {
                isAfter = true
                airport_list.push(f)
                return f
            } else if (f.positionId !== position_id && isAfter) {
                const previous_flight = airport_list[airport_list.length - 1]
                const f_current = {
                    airportFrom: f.airportFrom,
                    airportTo: f.airportTo,
                    departureDateFormat: f.departureDateFormat,
                    departureDate: f.departureDate,
                    availableSelectDate: previous_flight.availableSelectDate,
                    positionId: f.positionId,
                }
                airport_list.push(f_current)
                if (f.departureDate !== "") { isAfter = false }
                return f_current
            } else {
                airport_list.push(f)
                return f
            }
        }).filter(f => f.positionId !== position_id)]

        dispatch(updateFlightDataItem({ type: 'flights', value: flightList }))  ///update flights



        setError(false)
    }


    const setFlightsData = (flight, airport_from, airport_to, date) => {
        const pid = flight.positionId
        let departure_date = date !== null ? date : ""
        let departure_date_format = date === "" || moment(date).format('YYYY-MM-DD') === "Invalid date" ? "" : moment(date).format('YYYY-MM-DD')

        let sort_flights_pid = []
        let sort_flights_is_push = false
        flights.map(f => {
            if (f.positionId === pid) { sort_flights_is_push = true }
            return (
                sort_flights_is_push ? sort_flights_pid.push(f.positionId) : ""
            )
        })
        let selected_flight = {
            airportFrom: airport_from,
            airportTo: airport_to,
            departureDateFormat: departure_date_format,
            departureDate: departure_date,
            availableSelectDate: flight.availableSelectDate,
            positionId: flight.positionId,
        }
        let flightList;

        const flightCopy = [...flights];
        flightList = flightCopy.map((f) => {
            if (f.positionId === pid) {
                return selected_flight
            } else if (f.positionId !== pid && sort_flights_pid.find(sd => sd === f.positionId) !== undefined) {
                departure_date = departure_date_format === "" ? new Date().toDateString() : new Date(moment(departure_date_format).format("YYYY-MM-DD 00:00"))

                return {
                    airportFrom: f.airportFrom,
                    airportTo: f.airportTo,
                    departureDateFormat: f.departureDateFormat,
                    departureDate: f.departureDate,
                    availableSelectDate: departure_date,
                    positionId: f.positionId,
                }
            } else {
                return f
            }
        })


        dispatch(updateFlightDataItem({ type: 'flights', value: flightList }))  ///update flights
    }





    const handleFlightForm = () => {
        setError(true)
        let valide = true
        const flightCopy = [...flights];
        const flights_selected = flightCopy.map(f => {
            return {
                airportFrom: f.airportFrom,
                airportTo: f.airportTo,
                departureDateFormat: f.departureDateFormat || null,
                departureDate: f.departureDate || null,
                availableSelectDate: f.availableSelectDate,
                positionId: f.positionId,
            }
        })

        flights_selected.map(f => valide = f.airportFrom !== null && f.airportTo !== null && f.departureDateFormat !== null)
        // flights_selected.map(f => valide = f.airportFrom !== null && f.airportTo !== null && f.airportFrom.iata !== f.airportTo.iata)

        const dataToBookingFlights = {
            flightMode: flightMode,
            cabinClass: cabinClass,
            numberAdult: numberAdult,
            numberChild: numberChild,
            flights: flights_selected,
            currency: fullCurrency

        }
        if (flights_selected.length > 0 && valide) {
            searchFlight(dataToBookingFlights)
            // createMultipleDestinationTrip(uuid, lang, dataToCreateTrip, activeTrip.tripId)
        }
    }

    const location = useLocation()
    const history = useHistory()

    const searchFlight =async(dataToBookingFlights) => {

        try {
            const path = location.pathname;
            dispatch(toggleOneclick(false)) //reset oneclick
            // dispatch(updateFlightData(dataToBookingFlights))
            // dispatch(resetFlightPage())
            // dispatch(getFlightsListThunk(dataToBookingFlights))

            TrackEvent('SEARCH', "Flight search", JSON.stringify(dataToBookingFlights))

            const flightMode = dataToBookingFlights?.flightMode;
            const numPassengers = dataToBookingFlights?.numberAdult;
            const numChild = dataToBookingFlights?.numberChild;
            const cabin = dataToBookingFlights?.cabinClass;


            const flightDates = [...flights]?.map((flight) => {
                const formatDate = moment.parseZone(flight?.departureDateFormat)?.format("YYYY-MM-DD")?.toString()?.split("-");
                const dateString = `${formatDate[2]}${formatDate[0] < 10 ? "0" + formatDate[0] : formatDate[0]}${formatDate[1] < 10 ? "0" + formatDate[1] : formatDate[1]}`
                return `${flight?.airportFrom?.iata}-${flight?.airportTo?.iata}-${formatDate?.join('') ?? ""}`
            })





            let flightUrl = `/flight/search?flightMode=${flightMode}&cabinClass=${cabin}&numberAdult=${numPassengers}&numberChild=${numChild}&flights=${flightDates?.toString()}`;

            let currentUrl = location?.pathname + location?.search
            await dispatch(resetFlightPage())


            history.push(flightUrl)

            setTimeout(() => {
                setTimelineTripDestinationOpenStatus(activeTripDestinationId,"CLOSEOTHERS",activeDestinationId)
            }, 800);
            

            // if (currentUrl !== flightUrl) {
            //     dispatch(resetFlightPage())
            //     history.push(flightUrl)
            // }

            // if (path !== '/flight/search') {
            //     setBookingFormStatus(false)
            //     history.push('/flight/search')
            // }
        }
        catch (e) {
            console.log(e)
        }

    }

    const handleFlightMode = (mode) => {
        setError(false)
        dispatch(updateFlightDataItem({ type: 'mode', value: mode }))

        let flightArray = []
        if (mode === "round_trip") {
            if (flights && flights.length === 2) {
                flightArray = [...flights]
            }
            else {
                flightArray = [
                    flights[0] ? flights[0] : {
                        airportFrom: null,
                        airportTo: null,
                        departureDateFormat: "",
                        departureDate: "",
                        availableSelectDate: new Date().toDateString(),
                        positionId: "d-0",
                    },
                    {
                        airportFrom: null,
                        airportTo: null,
                        departureDateFormat: "",
                        departureDate: "",
                        availableSelectDate: new Date().toDateString(),
                        positionId: "d-1",
                    }
                ]
            }
        }
        if (mode === "one_way") {
            flightArray = [flights[0]]
        }
        if (mode === "multi_destination") {
            flightArray = [flights[0]]
        }


        dispatch(updateFlightDataItem({ type: 'flights', value: flightArray }))  ///update flights
    }

    return (
        <React.Fragment>
            <div className="row m-0 p-0">
                <div className="root-selection text-left col-12 mt-3 p-0">
                    <label className="radio-inline radio-1" onClick={() => handleFlightMode('round_trip')}>
                        <input onChange={(event) => { handleFlightMode(event.target.value) }} value='round_trip' type="radio" name="optradio" checked={flightMode === 'round_trip'} />Round-trip
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio-inline radio-2" onClick={() => handleFlightMode('one_way')}>
                        <input onChange={(event) => { handleFlightMode(event.target.value) }} value='one_way' type="radio" name="optradio" checked={flightMode === 'one_way'} />One-way
                        <span className="checkmark"></span>
                    </label>
                    <label className="radio-inline radio-3" onClick={() => handleFlightMode('multi_destination')}>
                        <input onChange={(event) => { handleFlightMode(event.target.value) }} value='multi_destination' type="radio" name="optradio" checked={flightMode === 'multi_destination'} />Multi-destination
                        <span className="checkmark"></span>
                    </label>
                </div>
            </div>
            {flightMode === "round_trip" && <div className="row m-0 p-0">

                {flights.length === 2 && <FlightFormFlightElementRoundTrip
                    departingFlight={flights[0]}
                    returningFlight={flights[1]}
                    setFlightsData={setFlightsData}
                    error={error} />}

                <div className="row col-12 m-0 p-0">
                    <div className="col-6 col-md-2 mt-3 p-0" >
                        <select className="form-control pl-3" placeholder="Adult" value={numberAdult} onChange={(e) => handleAdultsChange(e)} style={{ cursor: "pointer" }}>
                            <option value="1">1 Adult</option>
                            {Number(numberChild) < 7 && <option value="2">2 Adults</option>}
                            {Number(numberChild) < 6 && <option value="3">3 Adults</option>}
                            {Number(numberChild) < 5 && <option value="4">4 Adults</option>}
                            {Number(numberChild) < 4 && <option value="5">5 Adults</option>}
                            {Number(numberChild) < 3 && <option value="6">6 Adults</option>}
                            {Number(numberChild) < 2 && <option value="7">7 Adults</option>}
                            {Number(numberChild) < 1 && <option value="8">8 Adults</option>}
                        </select>
                    </div>
                    <div className="col-6 col-md-2 mt-3 p-0">
                        <select className="form-control pl-3" placeholder="Children" value={numberChild} onChange={(e) => handleChildrenChange(e)} style={{ cursor: "pointer" }}>
                            <option value="0" >Children</option>
                            {Number(numberAdult) < 8 && <option value="1">1 Child</option>}
                            {Number(numberAdult) < 7 && <option value="2">2 Children</option>}
                            {Number(numberAdult) < 6 && <option value="3">3 Children</option>}
                            {Number(numberAdult) < 5 && <option value="4">4 Children</option>}
                            {Number(numberAdult) < 4 && <option value="5">5 Children</option>}
                            {Number(numberAdult) < 3 && <option value="6">6 Children</option>}
                            {Number(numberAdult) < 2 && <option value="7">7 Children</option>}
                        </select>
                    </div>
                    <div className="col-6 col-md-2 mt-3 p-0">
                        <select value={cabinClass} className="form-control pl-3" onChange={(e) => handleCabinClassChange(e)} style={{ cursor: "pointer" }}>
                            <option value="economy">Economy</option>
                            <option value="premium">Premium Economy</option>
                            <option value="business">Business</option>
                            <option value="first">First</option>
                        </select>
                    </div>
                    <div className="col-6 col-md-6 p-0 flightSubmitBtn" >
                        <div className="col-4 offset-md-8  p-0" >
                            <button type="submit" disabled={false} className="btn btn-default submitBtn  col-12 mt-3 p-0" onClick={() => handleFlightForm()}>Search Flight</button>
                        </div>

                    </div>
                </div>

            </div>}
            {
                flightMode === "one_way" && <div className="row m-0 p-0">


                    {flights.length > 0 && flights.map((flight, index) => {
                        return (
                            <React.Fragment key={'one_way_flight' + index}>
                                <FlightFormFlightElement flight={flight} setFlightsData={setFlightsData} lengthFlights={flights.length} removeAddedFlight={removeAddedFlight} error={error} />
                            </React.Fragment>
                        )
                    })}

                    <div className="row col-12 m-0 p-0">
                        <div className="col-6 col-md-2 mt-3 p-0" >
                            <select className="form-control pl-3" placeholder="Adult" value={numberAdult} onChange={(e) => handleAdultsChange(e)} style={{ cursor: "pointer" }}>
                                <option value="1">1 Adult</option>
                                {Number(numberChild) < 7 && <option value="2">2 Adults</option>}
                                {Number(numberChild) < 6 && <option value="3">3 Adults</option>}
                                {Number(numberChild) < 5 && <option value="4">4 Adults</option>}
                                {Number(numberChild) < 4 && <option value="5">5 Adults</option>}
                                {Number(numberChild) < 3 && <option value="6">6 Adults</option>}
                                {Number(numberChild) < 2 && <option value="7">7 Adults</option>}
                                {Number(numberChild) < 1 && <option value="8">8 Adults</option>}
                            </select>
                        </div>
                        <div className="col-6 col-md-2 mt-3 p-0">
                            <select className="form-control pl-3" placeholder="Children" value={numberChild} onChange={(e) => handleChildrenChange(e)} style={{ cursor: "pointer" }}>
                                <option value="0" >Children</option>
                                {Number(numberAdult) < 8 && <option value="1">1 Child</option>}
                                {Number(numberAdult) < 7 && <option value="2">2 Children</option>}
                                {Number(numberAdult) < 6 && <option value="3">3 Children</option>}
                                {Number(numberAdult) < 5 && <option value="4">4 Children</option>}
                                {Number(numberAdult) < 4 && <option value="5">5 Children</option>}
                                {Number(numberAdult) < 3 && <option value="6">6 Children</option>}
                                {Number(numberAdult) < 2 && <option value="7">7 Children</option>}
                            </select>
                        </div>
                        <div className="col-6 col-md-2 mt-3 p-0">
                            <select value={cabinClass} className="form-control pl-3" onChange={(e) => handleCabinClassChange(e)} style={{ cursor: "pointer" }}>
                                <option value="economy">Economy</option>
                                <option value="premium">Premium Economy</option>
                                <option value="business">Business</option>
                                <option value="first">First</option>
                            </select>
                        </div>

                        <div className="col-6 col-md-6 p-0 flightSubmitBtn">
                            <div className="col-4 offset-md-8  p-0" >
                                <button type="submit" disabled={false} className="btn btn-default submitBtn col-12 mt-3 p-0" onClick={() => handleFlightForm()}>Search Flight</button>
                            </div>



                        </div>
                    </div>

                </div>
            }
            {
                flightMode === "multi_destination" && <div className="row m-0 p-0">

                    {flights.length > 0 && flights.map((flight, index) => {
                        return (
                            <React.Fragment key={'multi_destination' + index} >
                                <FlightFormFlightElement multiDestination={true} flight={flight} flights={flights} index={index} setFlightsData={setFlightsData} lengthFlights={flights.length} removeAddedFlight={removeAddedFlight} error={error} />
                            </React.Fragment>
                        )
                    })}
                    <div className="col-12 col-md-10 p-0 mt-3" style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
                        <button className="btn btn-default col-12 addDestinationBtn p-0" style={{ color: flights.length >= MAX_FLIGHTS ? "#212529" : "#19bc9b" }} onClick={() => handleAddFlight()} disabled={flights.length >= MAX_FLIGHTS}>
                            + Add another flight
                        </button>
                    </div>

                    <div className="col-12 col-md-2 p-0 flightSubmitBtn">
                        <button type="submit" disabled={false} className="btn btn-default submitBtn col-12 mt-3 p-0" onClick={() => handleFlightForm()}>Search Flight</button>
                    </div>
                    {showMoreOptionsInMultiCity &&
                        <React.Fragment>
                            <div className="col-6 col-md-2 mt-3 p-0" >
                                <select className="form-control pl-3" placeholder="Adult" value={numberAdult} onChange={(e) => handleAdultsChange(e)} style={{ cursor: "pointer" }}>
                                    <option value="1">1 Adult</option>
                                    {Number(numberChild) < 7 && <option value="2">2 Adults</option>}
                                    {Number(numberChild) < 6 && <option value="3">3 Adults</option>}
                                    {Number(numberChild) < 5 && <option value="4">4 Adults</option>}
                                    {Number(numberChild) < 4 && <option value="5">5 Adults</option>}
                                    {Number(numberChild) < 3 && <option value="6">6 Adults</option>}
                                    {Number(numberChild) < 2 && <option value="7">7 Adults</option>}
                                    {Number(numberChild) < 1 && <option value="8">8 Adults</option>}
                                </select>
                            </div>
                            <div className="col-6 col-md-2 mt-3 p-0">
                                <select className="form-control pl-3" placeholder="Children" value={numberChild} onChange={(e) => handleChildrenChange(e)} style={{ cursor: "pointer" }}>
                                    <option value="0" >Children</option>
                                    {Number(numberAdult) < 8 && <option value="1">1 Child</option>}
                                    {Number(numberAdult) < 7 && <option value="2">2 Children</option>}
                                    {Number(numberAdult) < 6 && <option value="3">3 Children</option>}
                                    {Number(numberAdult) < 5 && <option value="4">4 Children</option>}
                                    {Number(numberAdult) < 4 && <option value="5">5 Children</option>}
                                    {Number(numberAdult) < 3 && <option value="6">6 Children</option>}
                                    {Number(numberAdult) < 2 && <option value="7">7 Children</option>}
                                </select>
                            </div>
                            <div className="col-6 col-md-2 mt-3 p-0">
                                <select value={cabinClass} className="form-control pl-3" onChange={(e) => handleCabinClassChange(e)} style={{ cursor: "pointer" }}>
                                    <option value="economy">Economy</option>
                                    <option value="premium">Premium Economy</option>
                                    <option value="business">Business</option>
                                    <option value="first">First</option>
                                </select>
                            </div>
                        </React.Fragment>
                    }
                    <div className="row col-12 m-0 p-0">
                        <div className="col-md-12 p-0 bg-white mt-3 text-center showOptions">
                            {showMoreOptionsInMultiCity ?
                                <div style={{ display: "inline-block", cursor: "pointer", }} onClick={() => setShowMoreOptionsInMultiCity(false)}>{'Less options'} <UpArrowIcon style={{ marginLeft: "15px" }} fill={"var(--mainGreen"} /></div>
                                :
                                <div style={{ display: "inline-block", cursor: "pointer", }} onClick={() => setShowMoreOptionsInMultiCity(true)} > {'More options'} <DownArrowIcon style={{ marginLeft: "15px" }} fill={"var(--mainGreen"} /></div>}
                        </div>
                    </div>



                </div>


            }
            {/* <div style={{
                marginTop: "5px",
                color: "#b1b1b1",
                font: "18px/22px Futura Lt BT",
                width: '100%',
                textAlign: 'right'
            }}>
                powered by Priceline
            </div> */}

        </React.Fragment >
    )
}


const mapStateToProps = (state) => (()=>{

    return {
        lang: state.Setting.lang,
        uuid: state.Member.authModal.uuid,
        activeTripDestinationId: state.FormBank.TimelineWizard.activeTripDestinationId,
        activeDestinationId: state.FormBank.TimelineWizard.activeDestinationId,
    }
})

const mapDispatchToProps = {
    setBookingFormStatus: Actions.setBookingFormStatus,
    setTimelineTripDestinationOpenStatus: Actions.setTimelineTripDestinationOpenStatus,
}

const FlightForm = connect(mapStateToProps, mapDispatchToProps)(FlightForm_)
export default FlightForm
