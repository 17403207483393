import { TextField } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { environment } from '../../../../Environments/environment';
import { setHometownAirportSelected } from '../../../../redux/actions';
import { HometownAirportTextField, PasswordEye, PasswordTextField } from '../../Common/FormComponentsBank';
import { validate, validate_fields } from '../../Common/RegExValidate';
import { flightTextField } from '../../FlightsPage/flightStyles';
import { getIPFromAmazon } from '../../HotelPage/hotelServices';

export const GuestContactForm = ({ is_mobile, contactInfo, setContactInfo, guestList,emailAvailable,loggedIn }) => {
    const uuid = useSelector(state => state.Member.authModal.uuid);
    const contact = { ...contactInfo };
    const dispatch = useDispatch()

    const isValidEmail = validate(contact.email, validate_fields.email);
    const updateValue = (field, value) => {
        setValues(field, value)
    };

    const setValues = (field, value) => {
        contact[field] = value;
        setContactInfo(contact)
    }

    const classesAutocomplete = flightTextField()

    React.useEffect(()=>{
        const getAddress =async()=>{
            const airport  = await getIPFromAmazon();
            if(airport){
                dispatch(setHometownAirportSelected(airport, uuid, ""))
            }
  
        }
        getAddress()
    },[])

    return (
        <div>

            <div className="row">
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Last Name'
                            id='Last Name'
                            required={true}
                            value={contact.name_last}
                            onChange={(event) => updateValue('name_last', event.target.value)}
                        />
                    </div>
                </div>


                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='First Name'
                            required={true}
                            id='First Name'
                            value={contact.name_first}
                            onChange={(event) => updateValue('name_first', event.target.value)}
                        />
                    </div>
                </div>


            </div>

            <div className="row">
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Mobile Phone'
                            required={true}
                            id='Mobile Phone'
                            type='tel'
                            value={contact.phone_number}
                            error={contact.phone_number?.length > 0 ? !validate(contact.phone_number, validate_fields.phoneNumber) : false}
                            helperText={contact.phone_number?.length > 0 && !validate(contact.phone_number, validate_fields.phoneNumber) ?
                                "Please enter a valid phone number" : false}
                            onChange={(event) => updateValue('phone_number', event.target.value)}
                        />
                    </div>
                </div>


                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px",
                }}>

                    <div className={classesAutocomplete.root}>
                        <TextField
                            label='Email'
                            required={true}
                            type='email'
                            id='Email'
                            value={contact.email}
                            error={contact.email?.length > 0 ? (!isValidEmail || (!emailAvailable && !loggedIn) ) : false}
                            helperText={contact.email?.length > 0 && !isValidEmail ?
                                "Please enter a valid email address" : (!emailAvailable && isValidEmail && !loggedIn )? "This email exists in our database, login to save this booking and access it later": false}
                            onChange={(event) => updateValue('email', event.target.value)}
                        />
                    </div>
                </div>
            </div>


            {!environment?.production && (!uuid || emailAvailable) && <div>
            <div className="row">
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>
                    <div className={classesAutocomplete.root}>
                        <HometownAirportTextField
                            label="Home airport"
                            required={false}
                            id="hometown_airport"
                        />
                    </div>
                </div>

            </div>

            <div className="row position-relative">
                <div className={is_mobile ? 'col-12' : 'col-6'} style={{
                    marginTop: "20px", paddingRight: 0
                }}>
                    <div className={classesAutocomplete.root}>
                        <PasswordTextField

                            label="Password"
                            required={false}
                            id="password"
                        />
                    </div>
                </div>

                <div className="position-absolute text-right d-inline-block"
                    style={{
                        right: is_mobile? 23:"52%",
                        top: "45%",
                    }}>
                    <PasswordEye />
                </div>
            </div>
            </div>}



        </div>
    )
}