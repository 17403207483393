import * as Actions from '../../../redux/actions'

import Button from '@material-ui/core/Button'
import { DeleteIcon } from "./SVGBank"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { connect } from "react-redux"
import { list_container_mode } from '../Common/RegExValidate'
import { member_area_router } from '../Common/RegExValidate'
import { useHistory } from "react-router-dom"

/* delete dialog function component */
const DeleteDialog_ = ({ uuid,trip, tripPageTrip, tripPage, setOpenTripEllipsis = () => { }, tripMode, deleteTrip, setSwitchContainer, setTripDisplayMode, from, toggle_ellipsis_close_disable, switch_container,
  token, deleteTimelineWizard, setTimelineWizardDeleteAlartStatus, activeTrip, alartModalStatusDeleting, setOpenAuthModal }) => {
    const isHotelPage = document.location?.pathname?.includes("hotel");

  
  const [open, setOpen] = React.useState(false)
  /* open dialog */
  const handleClickOpen = () => {
    setOpen(true)

  }
  const selectedTrip  = tripPageTrip?tripPageTrip: trip;
  /* close dialog */
  const handleClose = () => {
    setOpen(false)
  }
  const history = useHistory()



  const goToNextTrip = () => {
    let nav_next = ""
    if (selectedTrip?.nav !== undefined && selectedTrip?.nav.length > 0) {
      nav_next = selectedTrip?.nav !== undefined && selectedTrip?.nav.length > 0 && selectedTrip?.nav[1] !== undefined && selectedTrip?.nav[1] !== null && selectedTrip?.nav[1].tripID !== undefined ? selectedTrip?.nav[1].tripID : ""
      const url = `/muver/trip/${tripMode}/${nav_next}`;
      history.push(url.toLowerCase())
    }
   

  }

  const handeDeleteTrip = (tripID, uuid, tripName) => {
    deleteTrip(tripID, uuid, tripName)
    setOpen(false)
    if (switch_container === member_area_router.memberAreaTrip || switch_container === member_area_router.trips) {
      history.push('/muver/trips')
    }

    if(tripPage){
      history.push('/')
    }
    
    // if(tripPage){
    //   history.push('/muver/trips')
    // }
  
    setOpenTripEllipsis(false)
  }


  return (
    <React.Fragment>
      {switch_container === member_area_router.memberAreaTrip || switch_container === member_area_router.trips || tripPage ? <div>
        {tripPage ?
          <div onClick={handleClickOpen}>
            <DeleteIcon fill={'white'} width={25} height={25} />
          </div>

          : <div onClick={handleClickOpen}>
            <DeleteIcon />{toggle_ellipsis_close_disable ? "" : from}
          </div>}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{selectedTrip?.tripName}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to " + from + "?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} >
              Cancel
            </Button>
            <Button onClick={() => handeDeleteTrip(selectedTrip?.tripID, uuid, selectedTrip?.tripName)} color="secondary" autoFocus >
              <DeleteIcon />{" Delete"}
            </Button>
          </DialogActions>
        </Dialog>
      </div> :
        <div>
          {tripPage ?
          <div onClick={() => setTimelineWizardDeleteAlartStatus(true)}>
            <DeleteIcon fill={'white'} width={25} height={25} />
          </div>

          : <div onClick={() => setTimelineWizardDeleteAlartStatus(true)}>
          <DeleteIcon />{toggle_ellipsis_close_disable ? "" : from}
        </div>}

          
          <Dialog open={alartModalStatusDeleting}
            aria-labelledby={alartModalStatusDeleting ? 'form_dialog_title' : undefined}
          >
            <DialogTitle id={alartModalStatusDeleting ? "form_dialog_title" : undefined}>{token === "" ? "Deleting an unsave trip." : "Deleting a trip."}</DialogTitle>
            <DialogContent>
              {token === "" && <div style={{ font: "16px/36px Futura Md BT", display: "flex" }} >
                <div style={{
                  cursor: "pointer",
                  font: "16px/36px Futura Md BT",
                  color: "var(--mainGreen)",
                  marginRight: "5px"
                }} onClick={() => setOpenAuthModal(true)}>Join/Login</div><div> to save it.</div>
              </div>}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                deleteTimelineWizard(token, activeTrip.tripId)
                // history.push('/home')
                }} color="secondary">
                Delete
              </Button>
              <Button onClick={() => setTimelineWizardDeleteAlartStatus(false)} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      }
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  uuid: state.Member.authModal.uuid,
  trip: state.Member.memberArea.selectedTrip,
  toggle_ellipsis_close_disable: state.Member.memberArea.toggle_ellipsis_close_disable,
  switch_container: state.Member.switch_container,
  tripMode: state.Member.tripMode,
  token: state.Member.authModal.token,
  alartModalStatusDeleting: state.FormBank.TimelineWizard.alartModalStatusDeleting,
  activeTrip: state.FormBank.TimelineWizard.activeTrip,

})

const mapDispatchToProps = {
  /* member area */
  deleteTrip: Actions.deleteTrip,

  setSwitchContainer: Actions.setSwitchContainer,
  setTripDisplayMode: Actions.setTripDisplayMode,
  /* end member area */

  /* timeline */
  setOpenAuthModal: Actions.setOpenAuthModal,
  setTimelineWizardDeleteAlartStatus: Actions.setTimelineWizardDeleteAlartStatus,
  deleteTimelineWizard: Actions.deleteTimelineWizard,

}


const DeleteDialog = connect(mapStateToProps, mapDispatchToProps)(DeleteDialog_)
export default DeleteDialog
